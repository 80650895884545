<template>
    <div id="development">
        <!-- 头部 -->
        <AppHead />
        <div class="developmentCont">
            <div class="moduleTitle">
                <span>{{ $t('common.navDevelop') }}</span>
            </div>
            <ul class="developmentList">
                <li class="itemEvent leftBox">
                    <div class="itemEventContBox leftEvent">
                        <div class="itemEventCont">
                            <p class="itemEventTime">2009</p>
                            <p class="itemEventName">{{ $t('development.event1') }}</p>
                        </div>
                        <div class="linebox">
                            <div class="centLine">
                                <div class="point"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="itemEvent rightBox">
                    <div class="itemEventContBox rightEvent">
                        <div class="linebox">
                            <div class="centLine">
                                <div class="point"></div>
                            </div>
                        </div>
                        <div class="itemEventCont">
                            <p class="itemEventTime">2013</p>
                            <p class="itemEventName">{{ $t('development.event2') }}</p>
                        </div>
                    </div>
                </li>
                <li class="itemEvent leftBox">
                    <div class="itemEventContBox leftEvent">
                        <div class="itemEventCont">
                            <p class="itemEventTime">2014</p>
                            <p class="itemEventName">{{ $t('development.event3') }}</p>
                        </div>
                        <div class="linebox">
                            <div class="centLine">
                                <div class="point"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="itemEvent rightBox">
                    <div class="itemEventContBox rightEvent">
                        <div class="linebox">
                            <div class="centLine">
                                <div class="point"></div>
                            </div>
                        </div>
                        <div class="itemEventCont">
                            <p class="itemEventTime">2016</p>
                            <p class="itemEventName">{{ $t('development.event4') }}</p>
                        </div>
                    </div>
                </li>
                <li class="itemEvent leftBox">
                    <div class="itemEventContBox leftEvent">
                        <div class="itemEventCont">
                            <p class="itemEventTime">2018</p>
                            <p class="itemEventName">{{ $t('development.event5') }}</p>
                        </div>
                        <div class="linebox">
                            <div class="centLine">
                                <div class="point"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="itemEvent rightBox">
                    <div class="itemEventContBox rightEvent">
                        <div class="linebox">
                            <div class="centLine">
                                <div class="point"></div>
                            </div>
                        </div>
                        <div class="itemEventCont">
                            <p class="itemEventTime">2019</p>
                            <p class="itemEventName">{{ $t('development.event6') }}</p>
                        </div>
                    </div>
                </li>
                <li class="itemEvent leftBox"> 
                    <div class="itemEventContBox leftEvent">
                        <div class="itemEventCont">
                            <p class="itemEventTime">2022</p>
                            <p class="itemEventName">{{ $t('development.event7') }}</p>
                            <p class="itemEventName">{{ $t('development.event7sub') }}  </p>
                        </div>
                        <div class="linebox">
                            <div class="centLine">
                                <div class="point"></div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="itemEvent rightBox">
                    <div class="itemEventContBox rightEvent">
                        <div class="linebox">
                            <div class="centLine">
                                <div class="point"></div>
                            </div>
                        </div>
                        <div class="itemEventCont">
                            <p class="itemEventTime">2023</p>
                            <p class="itemEventName">{{ $t('development.event8') }}</p>
                        </div>
                    </div>
                </li>
                <li class="itemEvent leftBox">
                    <div class="itemEventContBox leftEvent">
                        <div class="itemEventCont">
                            <p class="itemEventTime">2024</p>
                            <p class="itemEventName">{{ $t('development.event9') }} </p>
                        </div>
                        <div class="linebox">
                            <div class="centLine">
                                <div class="point"></div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <!-- 底部 -->
        <AppFoot />
    </div>
</template>
  
  
<script>
import AppHead from '../components/AppHead.vue'
import AppFoot from '../components/AppFoot.vue'

export default {
    name: 'DevelopmentHistory',
    components: {
        AppHead,
        AppFoot
    }

}
</script>

<style lang="less">
    .developmentCont{
        max-width: 25.6rem;
        margin: 0 auto;
        padding: 1.77rem 2.27rem 2.12rem 2.27rem;
        .moduleTitle{
            border-left: 0.15rem solid #FF5500;
            padding: 0 .27rem;
            font-weight: 500;
            font-size: 0.35rem;
            color: #888888;
            line-height: 0.48rem;
            text-align: left;
            font-style: normal;
            display: flex;
            flex-direction: column;
            margin-bottom: 0.85rem;
        }
        .developmentList {
            display: flex;
            flex-direction: column;
            .itemEvent {
                width: 100%;
                .itemEventContBox {
                    width: 50.4%;
                    display: flex;
                    margin-bottom: .56rem;
                    .itemEventCont {
                        flex: 1;
                        width: 100%;
                        padding: .19rem .44rem;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        .itemEventTime {
                            font-weight: 600;
                            font-size: 0.35rem;
                            color: #FFFFFF;
                            line-height: 0.5rem;
                            text-align: left;
                            font-style: normal;
                        }
                        .itemEventName {
                            font-weight: 400;
                            font-size: 0.2rem;
                            color: #888888;
                            line-height: 0.28rem;
                            text-align: right;
                            font-style: normal;
                        }
                    }
                    .linebox {
                        margin-left: auto;
                        height: auto;
                        width: 0.12rem;
                        border-top: 0.01rem solid #979797;
                        border-bottom: 0.01rem solid #979797;
                        display: flex;
                        justify-content: center;
                        .centLine {
                            height: 100%;
                            border-left: 0.01rem solid #979797;
                            position: relative;
                            .point {
                                position: absolute;
                                top: 30%;
                                left: 50%;
                                transform: translateX(-50%);
                                width: 0.11rem;
                                height: 0.11rem;
                                border-radius: 50%;
                                background: #FF5500;
                                transition: width 0.3s ease, height 0.3s ease; /* 添加过渡效果 */
                            }
                        }
                    }
                }

                .leftEvent {
                    .itemEventCont {
                        margin-right: 1.99rem;
                        align-items: flex-end;
                        background-size: 200% 100%;
                        background-position: 0 0;
                        transition: background-position 0.5s ease;
                    }
                }

                .rightEvent {
                    margin-left: auto;
                    .itemEventCont {
                        margin-left: 1.99rem;
                        align-items: flex-start;
                        background-size: 200% 100%;
                        background-position: 100% 0;
                        transition: background-position 0.5s ease;
                        .itemEventName {
                            text-align: left;
                        }
                    }
                }
            }
            
            .leftBox:hover {
                .leftEvent {
                    .itemEventCont {
                        .itemEventName {
                            color: #FFFFFF;
                        }
                        background-image: linear-gradient(90deg, #000000 50%, #FF5500 100%);
                        background-position: 100% 0;
                    }
                    .linebox {
                        .centLine {
                            .point {
                                width: 0.2rem;
                                height: 0.2rem;
                            }
                        }
                    }
                }
            }

            .rightBox:hover {
                .rightEvent {
                    .itemEventCont {
                        .itemEventName {
                            color: #FFFFFF;
                        }
                        background-image: linear-gradient(270deg, #000000 50%, #FF5500 100%);
                        background-position: 0 0;
                    }
                    .linebox {
                        .centLine {
                            .point {
                                width: 0.2rem;
                                height: 0.2rem;
                            }
                        }
                    }
                }
            }
        }

    }
</style>