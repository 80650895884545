<template>
  <div id="app">
    <div class="index">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      // isWideScreen: true, // 默认认为是宽屏
    };
  },
  mounted() {
    // 初始化设置
    this.setHtmlFontSize();
    // 监听窗口变化，动态调整 html 的 font-size 和屏幕宽度
    window.addEventListener('resize', () => {
      this.setHtmlFontSize();
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {
      this.setHtmlFontSize();
    });
  },
  methods: {
    setHtmlFontSize() {
      const clientWidth = document.documentElement.clientWidth || window.innerWidth;
      const designWidth = 1920; // 设计稿宽度（假设为 1920px）
      const baseSize = 10; // 设置 1rem = 10px

      // 动态计算并设置 html 的 font-size
      const fontSize = (clientWidth / designWidth) * baseSize * 10; // 乘以10来适配 rem
      document.documentElement.style.fontSize = fontSize + 'px';
    },
  },
}
</script>

<style lang="less">
html,
body {
    margin: 0;
    padding: 0;
    font-family: PingFangSC, PingFang SC;
    background: #000000;
    a {
      text-decoration: none;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    img, p {
      margin: 0;
      padding: 0;
    }
}

.index {
  margin: 0 auto;
}

#page {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 500;
  margin: 0 auto;
}

</style>
