import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    routesName: {
      'Index': 'Index',
      'DevelopmentHistory': 'DevelopmentHistory',
      'LatestNews': 'LatestNews',
      'InvestorRelations': 'InvestorRelations',
      'CareerOpportunities': 'CareerOpportunities',
      'ContactUs': 'ContactUs'
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
