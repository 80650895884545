<template>
    <div class="main">
        <div class="AppHeadWrap">
            <div class="AppHeadCont">
                <router-link class="headLogo" to="/">
                    <img class="logo"  :src="headLogo" alt="head-logo@2x.webp">
                </router-link>
                <!-- 导航 -->
                <ul class="topNavList">
                  <li class="itemNav">
                    <router-link class="nav-link" to="/">{{ $t('common.navAboutUs') }}</router-link>
                  </li>
                  <li class="itemNav">
                    <router-link class="nav-link" to="/development">{{ $t('common.navDevelop') }}</router-link>
                  </li>
                  <li class="itemNav newsBox">
                    <router-link class="nav-link" to="/news">{{ $t('common.navLatestNews') }}</router-link>
                    <ul class="newsList">
                      <li class="itemNewsNav"><router-link class="news-link" :to="{ path: '/news', hash: '#newsCenterA' }">{{ $t('common.navNews1') }}</router-link></li>
                      <li class="itemNewsNav"><router-link class="news-link" :to="{ path: '/news', hash: '#socialImpactA' }">{{ $t('common.navNews2') }}</router-link></li>
                      <li class="itemNewsNav"><router-link class="news-link" :to="{ path: '/news', hash: '#mediaMaterialsA' }">{{ $t('common.navNews3') }}</router-link></li>
                    </ul>
                  </li>
                  <li class="itemNav">
                    <a class="nav-link" href="https://ir.newborntown.com/" rel="noopener noreferrer">{{ $t('common.navInvestor') }}</a>
                  </li>
                  <li class="itemNav">
                    <router-link class="nav-link" to="/employment">{{ $t('common.navEmployment') }}</router-link>
                  </li>
                  <li class="itemNav">
                    <router-link class="nav-link" to="/contactUs">{{ $t('common.navContactUs') }}</router-link>
                  </li>
                </ul>

                <!-- 多语言 -->
                <div class="languageWrap">
                    <div class="languageIcon">
                    <svg t="1723706015430" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6939" width="20" height="20"><path d="M512 0C230.4 0 0 230.4 0 512s230.4 512 512 512 512-230.4 512-512S793.6 0 512 0z m0 102.4c56.32 0 112.64 10.24 163.84 35.84v51.2c-5.12-5.12-35.84-30.72-35.84-30.72L399.36 240.64c-15.36 5.12-20.48 30.72-10.24 46.08l97.28 92.16-30.72 46.08-76.8-25.6c-5.12 0-15.36 0-20.48 5.12L240.64 481.28 204.8 563.2 153.6 460.8l-40.96-30.72C148.48 245.76 312.32 102.4 512 102.4z m-409.6 419.84l5.12 5.12 15.36 10.24L184.32 665.6c10.24 20.48 35.84 20.48 46.08 0l66.56-128 87.04-56.32 87.04 30.72c10.24 5.12 20.48 0 30.72-10.24l76.8-117.76c5.12-10.24 5.12-25.6-5.12-30.72L506.88 286.72l117.76-40.96 15.36 15.36c25.6 20.48 56.32 35.84 81.92 25.6 15.36-5.12 25.6-25.6 25.6-46.08V179.2c61.44 46.08 112.64 112.64 143.36 184.32l-35.84 5.12c-5.12 0-15.36 5.12-15.36 10.24-20.48 35.84-71.68 128-66.56 117.76-5.12-5.12-25.6-46.08-35.84-71.68-5.12-15.36-25.6-20.48-35.84-10.24l-133.12 81.92c-10.24 5.12-15.36 25.6-10.24 35.84l102.4 158.72 56.32 30.72c10.24 5.12 15.36 20.48 10.24 30.72l-40.96 81.92v46.08c-51.2 25.6-112.64 40.96-174.08 40.96-225.28 0-404.48-179.2-409.6-399.36z m670.72 307.2l40.96-112.64c5.12-10.24 0-25.6-10.24-30.72l-87.04-51.2-61.44-97.28 35.84-20.48v5.12c20.48 35.84 46.08 56.32 71.68 61.44 35.84 5.12 61.44-20.48 71.68-35.84 30.72-46.08 51.2-76.8 61.44-97.28l20.48-5.12c5.12 20.48 5.12 40.96 5.12 66.56 0 128-56.32 240.64-148.48 317.44z" fill="#FFFFFF" p-id="6940"></path></svg>
                    </div>
                    <ul class="languageDropdown">
                    <li class="item" @click="changeLanguage('hk')">{{ $t('common.hkLanguage') }}</li>
                    <li class="item" @click="changeLanguage('en')">{{ $t('common.enLanguage') }}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="indexCont">
          <div class="banner" ref="banner">
                <img class="bannerImg" src="../assets/images/index/indexBanner@2x.webp" alt="bannerimg">
                <div class="bannerText" ref="bannerText" :class="{ animate: isVisible }">{{ $t('index.bannerText') }}</div>
          </div>
            <div class="otherCont">
                <div class="aboutBox" ref="aboutBox">
                    <div class="aboutImgBox">
                        <img class="aboutImg" src="../assets/images/index/aboutUs-logo@2x.webp" alt="aboutUs">
                    </div>
                    <div class="aboutCont">
                        <div class="aboutTopBox">
                            <p class="title">{{ $t('common.navAboutUs') }}</p>
                            <p class="subtitle">{{ $t('index.aboutUsText') }}</p>
                        </div>
                        <div class="aboutApp">
                            <img class="itemAppIcon" src="../assets/images/index/medium-MICO@2x.webp" alt="">
                            <img class="itemAppIcon" src="../assets/images/index/medium-TopTop@2x.webp" alt="">
                            <img class="itemAppIcon" src="../assets/images/index/medium-TopTop@2x(1).webp" alt="">
                            <img class="itemAppIcon" src="../assets/images/index/medium-sugo@2x.webp" alt="">
                            <img class="itemAppIcon" src="../assets/images/index/medium-heesay@2x.webp" alt="">
                            <img class="itemAppIcon" src="../assets/images/index/medium-Blued@2x.webp" alt="">
                            <img class="itemAppIcon" src="../assets/images/index/medium-7@2x.webp" alt="">
                            <img class="itemAppIcon" src="../assets/images/index/medium-Sudoku@2x.webp" alt="">
                            <img class="itemAppIcon" src="../assets/images/index/medium-Sudoku@2x(1).webp" alt="">
                        </div>
                    </div>
                </div>
                <div class="distributionInformation">
                    <ul class="digitalInfo">
                        <li class="itemInfo">
                            <p class="itemInfoNum">20+</p>
                            <p class="itemInfoText">{{ $t('index.globalOperations') }}</p>
                        </li>
                        <li class="itemInfo">
                            <p class="itemInfoNum">{{ $t('index.unit') }}</p>
                            <p class="itemInfoText">{{ $t('index.globalUsers') }}</p>
                        </li>
                        <li class="itemInfo">
                            <p class="itemInfoNum">150+</p>
                            <p class="itemInfoText">{{ $t('index.countryAndRegion') }}</p>
                        </li>
                    </ul>
                    <div class="mapInfo">
                        <img class="mapInfoImg" :src="mapUrl" alt="加香港海口">
                    </div>
                </div>
            </div>
        </div>
        <AppFoot />
    </div>
</template>

<script>
import AppFoot from '../components/AppFoot.vue'
import { mapState } from 'vuex'

export default {
    name: 'HomeIndex',
    components: {
        AppFoot
    },
    data() {
        return {
          // activeIndex: null,  // 用于存储当前激活的索引
          mapUrl: '',
          headLogo: '',
          isVisible: false
        }
    },
    computed: {
        ...mapState(['routesName'])
    },
    watch: {
        '$route.name'(newValue) {
          console.log('watch:', newValue)
        },
        '$i18n.locale': {
          immediate: true,  // 立即执行一次以确保在组件加载时正确设置 mapUrl
          handler(newValue) {
            if (newValue === 'en') {
              this.mapUrl = require('@/assets/images/index/地图英文@2x.webp');
              this.headLogo = require('@/assets/images/index/headLogo-en@2x.webp');
            } else if (newValue === 'hk') {
              this.mapUrl = require('@/assets/images/index/加香港海口-05@2x.webp');
              this.headLogo = require('@/assets/images/index/headLogo@2x.webp');
            } else {
              this.mapUrl = ''; // 确保在不匹配的情况下清除 mapUrl
              this.headLogo = '';
            }
          }
        }
    },
    created() {
        // this.activeIndex = this.routesName[this.$route.name]
    },
    mounted() {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // .bannerText 元素触发动画
                    if (entry.target.classList.contains('bannerText')) {
                        entry.target.classList.add('animate');
                    }

                    // .aboutApp 内部的 .itemAppIcon 元素触发动画
                    if (entry.target.classList.contains('aboutApp')) {
                        const icons = entry.target.querySelectorAll('.itemAppIcon');
                        icons.forEach((icon, index) => {
                            icon.style.animationDelay = `${index * 0.1}s`;
                            icon.classList.add('show');
                        });
                    }

                    // .digitalInfo 内部的 .itemInfo 元素触发动画
                    if (entry.target.classList.contains('itemInfo')) {
                        entry.target.classList.add('ladder');
                    }
                } else {
                    // 如果需要在元素离开视口时移除动画效果
                    if (entry.target.classList.contains('bannerText')) {
                        entry.target.classList.remove('animate');
                    }

                    if (entry.target.classList.contains('aboutApp')) {
                        const icons = entry.target.querySelectorAll('.itemAppIcon');
                        icons.forEach(icon => icon.classList.remove('show'));
                    }

                    if (entry.target.classList.contains('itemInfo')) {
                        entry.target.classList.remove('ladder');
                    }
                }
            });
        }, {
            threshold: 0.1 // 只有当元素的10%进入视口时才触发
        });

        // 观察 .bannerText 元素
        const bannerTextEl = this.$refs.bannerText;
        if (bannerTextEl) {
            observer.observe(bannerTextEl);
        }

        // 观察 .aboutApp 元素
        const aboutAppEl = this.$refs.aboutBox.querySelector('.aboutApp');
        if (aboutAppEl) {
            observer.observe(aboutAppEl);
        }

        // 观察所有 .itemInfo 元素
        const itemInfoEls = this.$el.querySelectorAll('.itemInfo');
        itemInfoEls.forEach(item => observer.observe(item));
    },

    methods: {
        changeLanguage(command){
            this.$i18n.locale = command;
        },
        onVisibilityChange(isVisible) {
          if (isVisible) {
            this.isVisible = true;
          }
        }

    }
}
</script>

<style lang="less" scoped>
.AppHeadWrap{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    height: .8rem;
    // background-color: #000000;
    border-bottom: none;
      .AppHeadCont{
        // background-color: rgba(0, 0, 0, .3);
        background-color: #000000;
        border-bottom: none;
        max-width: 25.6rem;
        height: .8rem;
        padding: 0 0.853rem;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .headLogo{
          display: flex;
          height: 100%;
          align-items: center;
          .logo{
            height: 0.35rem;
            width: auto;
          }
        }
        .topNavList{
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          // .nav-link.router-link-active {
          //   border-bottom: .013rem solid #FFFFFF;
          // }
          // .nav-link.router-link-exact-active {
          //   border-bottom: .013rem solid #FFFFFF;
          // }
          .itemNav {
            margin-right: 0.6rem;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .nav-link{
              font-weight: 500;
              font-size: .16rem;
              color: #FFFFFF;
              line-height: .26rem;
              text-align: left;
              font-style: normal;
            }
            &:hover{
              .nav-link{
                border-bottom: .013rem solid #FFFFFF;
              }
            }
          }
          .newsBox{
            position: relative;
            .newsList{
              display: none;
              width: 1.2rem;
              padding: .32rem;
              background: #FFFFFF;
              position: absolute;
              top: .8rem;
              left: 50%;
              transform: translateX(-50%);
              z-index: 2;
              .itemNewsNav{
                display: flex;
                margin-bottom: .2rem;
                .news-link{
                  font-weight: 500;
                  font-size: .16rem;
                  color: #000000;
                  line-height: .22rem;
                  text-align: left;
                  font-style: normal;
                }
              }
            }
            &:hover{
              .newsList {
                display: block;
              }
            }
          }
        }
        .languageWrap{
          height: 100%;
          width: 0.66rem;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          .languageIcon{
            height: 100%;
            display: flex;
            align-items: center;
            .icon{
              width: .26rem;
              height: .26rem;
            }
          }
          .languageDropdown{
            display: none;
            position: absolute;
            top: .8rem;
            left: 50%;
            transform: translate(-50%);
            padding: .32rem;
            background: #FFFFFF;
            // display: flex;
            align-content: center;
            flex-direction: column;
            z-index: 2;
            .item{
              font-weight: 500;
              font-size: .16rem;
              color: #000000;
              line-height: .22rem;
              text-align: left;
              font-style: normal;
              margin-bottom: .2rem;
            }
          }
          &:hover{
            .languageDropdown{
              display: flex;
            }
          }
        }
      }

      .text{
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: flex-end;
        align-content: center;
      }
      .el-menu-demo{
        flex: 1;
      }
      .menuList{
        display: flex;
        align-items: center;
        .el-link{
          font-weight: 500;
          font-size: .875rem;
          color: #FFFFFF;
          line-height: 1.25rem;
          text-align: left;
          font-style: normal;
        }
      }
      .el-menu.el-menu--horizontal {
        border-bottom: none;
      }
      .el-menu--horizontal>.el-menu-item.is-active {
          border-bottom: .02rem solid #FFFFFF;
          color: #FFFFFF;
      }
      .el-menu--popup {
        background: #FFFFFF;
      }
      .el-dropdown-link{
        display: flex;
        align-items: center;
        justify-content: center;
        .icon{
          width: 2rem;
          height: 2rem;
        }
      }
      .el-dropdown-menu {
        background: #FFFFFF;
      }
      .el-dropdown-link {
        cursor: pointer;
        color: #000000;
      }
      .el-icon-arrow-down {
        font-size: .012rem;
      }
}
.indexCont{
    // max-width: 25.6rem;
    margin: 0 auto;
    .banner{
        position: relative;
        .bannerImg{
            height: auto;
            width: 100%;
            // width: 100%;
            // height: 100vh;
            object-fit: cover;
            background: #000000;
            opacity: 0.5;
        }
        .bannerText {
          /* 你的初始样式 */
          width: 100%;
          padding: 0 5%;
          text-align: center;
          box-sizing: border-box;
          position: absolute;
          top: 50%;
          left: 50%;
          font-weight: 500;
          font-size: 1.1rem;
          color: #FFFFFF;
          line-height: 1.6rem;
          font-style: normal;
          white-space: wrap;
          z-index: 1;
          overflow: hidden;
          opacity: 0;
          transform: translate(-50%, 100%);
          transition: transform 1s ease-out, opacity 1s ease-out;
        }

        .bannerText.animate {
          opacity: 1;
          transform: translate(-50%, -50%);
        }
        // .bannerText {
        //     width: 100%;
        //     padding: 0 5%;
        //     text-align: center;
        //     box-sizing: border-box; /* 确保padding不会影响元素的整体宽度 */
        //     position: absolute;
        //     top: 50%;
        //     left: 50%;
        //     font-weight: 500;
        //     font-size: 1.1rem;
        //     color: #FFFFFF;
        //     line-height: 1.6rem;
        //     font-style: normal;
        //     white-space: wrap;
        //     z-index: 1;
        //     overflow: hidden;
        //     // opacity: 0; 
        //     // transform: translate(-50%, 100%);
        //     // animation: slideUp 1s ease-out forwards;
        //     opacity: 0;
        //     transform: translate(-50%, 100%);
        //     transition: transform 1s ease-out, opacity 1s ease-out;
        // }
        // .bannerText.animate {
        //   opacity: 1;
        //   transform: translate(-50%, 100%);
        // }

        // @keyframes slideUp {
        //     0% {
        //         opacity: 0;
        //         transform: translate(-50%, 100%);
        //     }
        //     50% {
        //         opacity: 0; 
        //         transform: translate(-50%, 50%); 
        //     }
        //     100% {
        //         opacity: 1;
        //         transform: translate(-50%, -50%); 
        //     }
        // }
    }
    .otherCont{
        padding: 2.73rem 2.26rem 2.12rem 2.26rem;
        .aboutBox{
            margin-bottom: 3.76rem;
            display: flex;
            justify-content: space-between;
            .aboutImgBox{
                margin-right: .7rem;
                width: 42%;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .aboutCont{
                flex: 1;
                .aboutTopBox{
                    padding-bottom: 1.8rem;
                    border-bottom: 0.01rem solid rgba(104, 104, 104, 0.5);
                    .title{
                        font-weight: 500;
                        font-size: 0.5rem;
                        color: #FFFFFF;
                        line-height: 1rem;
                        text-align: left;
                        font-style: normal;
                    }
                    .subtitle{
                        font-weight: 400;
                        font-size: .2rem;
                        color: #888888;
                        line-height: 0.38rem;
                        text-align: left;
                        font-style: normal;
                    }
                }
              .aboutApp {
                margin-top: .56rem;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
              }

              .itemAppIcon {
                width: 0.58rem;
                height: 0.58rem;
                margin-right: 0.19rem;
                margin-bottom: 0.19rem;
                opacity: 0; /* 初始透明度为0 */
                transform: translateX(0.58rem); /* 初始位置稍微向左 */
                transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out; /* 使用 transition 代替 animation */
              }

              .itemAppIcon.show {
                opacity: 1; /* 最终透明度为1 */
                transform: translateX(0); /* 最终位置恢复正常 */
              }

              /* 为每个 itemAppIcon 设置不同的延迟 */
              .itemAppIcon:nth-child(1).show {
                transition-delay: 0s;
              }
              .itemAppIcon:nth-child(2).show {
                transition-delay: 0.1s;
              }
              .itemAppIcon:nth-child(3).show {
                transition-delay: 0.2s;
              }
              .itemAppIcon:nth-child(4).show {
                transition-delay: 0.4s;
              }
              .itemAppIcon:nth-child(5).show {
                transition-delay: 0.5s;
              }
              .itemAppIcon:nth-child(6).show {
                transition-delay: 0.6s;
              }
              .itemAppIcon:nth-child(7).show {
                transition-delay: 0.7s;
              }
              .itemAppIcon:nth-child(8).show {
                transition-delay: 0.8s;
              }
              .itemAppIcon:nth-child(9).show {
                transition-delay: 0.9s;
              }

            }
        }
        .distributionInformation{
            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
            .digitalInfo {
                display: flex;
                flex-direction: column;
            }

            .itemInfo {
                margin-bottom: 0.5rem;
                opacity: 0; /* 初始透明度为0 */
                // transform: translateX(-3rem); /* 初始位置偏移 */
                transition: opacity 0.5s ease-out, transform 0.5s ease-out; /* 设置过渡效果 */
            }

            /* 为每个 itemInfo 设置不同的延迟 */
            .itemInfo.ladder:nth-child(1) {
                transition-delay: 0s;
            }

            .itemInfo.ladder:nth-child(2) {
                transition-delay: 0.2s;
            }

            .itemInfo.ladder:nth-child(3) {
                transition-delay: 0.4s;
            }

            /* 添加动画效果 */
            .itemInfo.ladder {
                opacity: 1; /* 动画后透明度为1 */
                // transform: translateX(0); /* 动画后位置回到正常 */
            }

            /* 保留原有样式设置 */
            .itemInfoNum {
                font-weight: 600;
                font-size: 0.54rem;
                color: #FFFFFF;
                line-height: .8rem;
                text-align: left;
                font-style: normal;
                opacity: 1; /* 确保在动画时不改变透明度 */
                transform: translateX(0); /* 确保在动画时不改变位置 */
            }

            .itemInfoText {
                font-weight: 500;
                font-size: 0.2rem;
                color: #888888;
                line-height: 0.28rem;
                text-align: left;
                font-style: normal;
                opacity: 1; /* 确保在动画时不改变透明度 */
                transform: translateX(0); /* 确保在动画时不改变位置 */
            }

            /* 地图介绍 - 自定义动画 */
            @keyframes digitalInfoSlideIn {
                0% {
                    opacity: 0;
                    transform: translateX(-3rem);
                }
                100% {
                    opacity: 1;
                    transform: translateX(0);
                }
            }
            .mapInfo{
                flex: 1;
                margin-left: 1rem;
                .mapInfoImg{
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}
</style>
