<template>
    <div class="MobileIndex">
        <div class="mHeadBox">
            <div class="mHeadWrap">
                <a href="/">
                    <img class="mheadLogo" :src="headLogo" alt="M-head-logo">
                </a>
                <div class="menu">
                    <div class="sideBar" @click="drawer = true">
                        <svg t="1724321491595" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1866" width="200" height="200"><path d="M44.4 245.9h935.2c24.4 0 44.4-20 44.4-44.4s-19.8-44.4-44.4-44.4H44.4C20 157.1 0 177.1 0 201.5s20 44.4 44.4 44.4z m935.2 221.8H44.4C20 467.7 0 487.7 0 512.1s20 44.4 44.4 44.4h935.2c24.4 0 44.4-20 44.4-44.4s-20-44.4-44.4-44.4z m0 310.4H44.4C20 778.1 0 797.8 0 822.5s20 44.4 44.4 44.4h935.2c24.4 0 44.4-19.8 44.4-44.4s-20-44.4-44.4-44.4z" p-id="1867" fill="#ffffff"></path></svg>
                    </div>
                </div>
            </div>
        </div>
        <el-drawer
            title="我是标题"
            :visible.sync="drawer"
            :with-header="false">
            <ul class="sideNavLits">
                <li class="itemNav"  @click="drawer = false">
                    <a class="nav-link" href="#nav1">{{ $t('common.navAboutUs') }}</a>
                </li>
                <li class="itemNav" @click="drawer = false">
                    <a class="nav-link" href="#nav2">{{ $t('common.navDevelop') }}</a>
                </li>
                <li class="itemNav" @click="drawer = false">
                    <a class="nav-link" href="#nav3">{{ $t('common.navLatestNews') }}</a>
                </li>
                <li class="itemNav">
                    <a class="nav-link" href="https://ir.newborntown.com/" rel="noopener noreferrer">{{ $t('common.navInvestor') }}</a>
                </li>
                <li class="itemNav" @click="drawer = false">
                    <a class="nav-link" href="#nav4">{{ $t('common.navEmployment') }}</a>
                </li>
                <li class="itemNav" @click="drawer = false">
                    <a class="nav-link" href="#nav5">{{ $t('common.navContactUs') }}</a>
                </li>
            </ul>
            <ul class="sidelanguage" @click="drawer = false">
                <li class="itemLang" @click="changeLanguage('hk')">{{ $t('common.hkLanguage') }}</li>
                <li class="itemLang" @click="changeLanguage('en')">{{ $t('common.enLanguage') }}</li>
            </ul>
        </el-drawer>
        <div class="mindexCont">
            <div class="banner">
                <img class="bannerImg" src="../assets/images/index/indexBanner@2x.webp" alt="bannerimg">
                <p class="bannerText">{{ $t('index.bannerText') }}</p>
            </div>
            <div class="otherModules">
                <!-- 关于我们 -->
                <div class="aboutModules" id="nav1">
                    <div class="itemModulesTitle">
                        <span class="line"></span>
                        <span class="titleText">{{ $t('common.navAboutUs') }}</span>
                    </div>
                    <div class="aboutModulesCont">
                        <div class="aboutbanner">
                            <img class="aboutbannerImg" src="../assets/images/index/aboutUs-logo@2x.webp" alt="aboutImg">
                            <p class="aboutbannerText">{{ $t('index.aboutUsText') }}</p>
                        </div>
                        <div class="aboutApp">
                            <img class="itemAppIcon" src="../assets/images/index/medium-Blued@2x.webp" alt="">
                            <img class="itemAppIcon" src="../assets/images/index/medium-heesay@2x.webp" alt="">
                            <img class="itemAppIcon" src="../assets/images/index/medium-MICO@2x.webp" alt="">
                            <img class="itemAppIcon" src="../assets/images/index/medium-Sudoku@2x(1).webp" alt="">
                            <img class="itemAppIcon" src="../assets/images/index/medium-TopTop@2x(1).webp" alt="">
                            <img class="itemAppIcon" src="../assets/images/index/medium-sugo@2x.webp" alt="">
                            <img class="itemAppIcon" src="../assets/images/index/medium-7@2x.webp" alt="">
                            <img class="itemAppIcon" src="../assets/images/index/medium-TopTop@2x.webp" alt="">
                            <img class="itemAppIcon" src="../assets/images/index/medium-Sudoku@2x.webp" alt="">
                        </div>
                        <div class="aboutMap">
                            <img class="mapImg" :src="mobileMapUrl" alt="map">
                            <ul class="digitalInfo">
                                <li class="itemInfo">
                                    <p class="itemInfoNum">20+</p>
                                    <p class="itemInfoText">{{ $t('index.globalOperations') }}</p>
                                </li>
                                <li class="itemInfo">
                                    <p class="itemInfoNum">{{ $t('index.unit') }}</p>
                                    <p class="itemInfoText">{{ $t('index.globalUsers') }}</p>
                                </li>
                                <li class="itemInfo">
                                    <p class="itemInfoNum">150+</p>
                                    <p class="itemInfoText">{{ $t('index.countryAndRegion') }}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- 發展里程 -->
                <div class="developmentHistoryModule" id="nav2">
                    <div class="itemModulesTitle">
                        <span class="line"></span>
                        <span class="titleText">{{ $t('common.navDevelop') }}</span>
                    </div>
                    <ul class="developmentList">
                        <li class="itemdevelp">
                            <div class="itemEventLeft">
                                <span class="point"></span>
                                <span class="itemTime">2009</span>
                            </div>
                            <div class="itemEventBox">
                                <span class="itemEvent">{{ $t('development.event1') }}</span>
                            </div>
                        </li>
                        <li class="itemdevelp">
                            <div class="itemEventLeft">
                                <span class="point"></span>
                                <span class="itemTime">2013</span>
                            </div>
                            <div class="itemEventBox">
                                <span class="itemEvent">{{ $t('development.event2') }}</span>
                            </div>
                        </li>
                        <li class="itemdevelp">
                            <div class="itemEventLeft">
                                <span class="point"></span>
                                <span class="itemTime">2014</span>
                            </div>
                            <div class="itemEventBox">
                                <span class="itemEvent">{{ $t('development.event3') }}</span>
                            </div>
                        </li>
                        <li class="itemdevelp">
                            <div class="itemEventLeft">
                                <span class="point"></span>
                                <span class="itemTime">2016</span>
                            </div>
                            <div class="itemEventBox">
                                <span class="itemEvent">{{ $t('development.event4') }}</span>
                            </div>
                        </li>
                        <li class="itemdevelp">
                            <div class="itemEventLeft">
                                <span class="point"></span>
                                <span class="itemTime">2018</span>
                            </div>
                            <div class="itemEventBox">
                                <span class="itemEvent">{{ $t('development.event5') }}</span>
                            </div>
                        </li>
                        <li class="itemdevelp">
                            <div class="itemEventLeft">
                                <span class="point"></span>
                                <span class="itemTime">2019</span>
                            </div>
                            <div class="itemEventBox">
                                <span class="itemEvent">{{ $t('development.event6') }}</span>
                            </div>
                        </li>
                        <li class="itemdevelp">
                            <div class="itemEventLeft">
                                <span class="point"></span>
                                <span class="itemTime">2022</span>
                            </div>
                            <div class="itemEventBox">
                                <p class="itemEvent">{{ $t('development.event7') }}</p>
                                <p class="itemEvent">{{ $t('development.event7sub') }}</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <!-- 最新動態 -->
                <div class="newsModules" id="nav3">
                    <div class="itemModulesTitle">
                        <span class="line"></span>
                        <span class="titleText">{{ $t('common.navLatestNews') }}</span>
                    </div>
                    <div class="newsList">
                        <div class="itemNewsInfo" v-for="item, index in lists" :key="index" @click.stop="show(item.name)">
                            <div class="itemNewsImgBox">
                                <img class="itemNewsImg" :src="item.imgUrl" alt="NEWS-IMG">
                            </div>
                            <div class="itemNewsInfoCont">
                                <div class="itemTopInfoTitle">{{ item.title }}</div>
                                <div class="itemBotInfo">
                                    <div class="itemBotInfoTime">{{ item.date }}</div>
                                    <div class="itemBotInfoMore">
                                        <span>{{ $t('news.seeMore') }}</span>
                                        <svg t="1724385231536" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4286" width="20" height="20"><path d="M642.5 513.1L310 180.6c-15.7-15.7-15.7-41.2 0-56.9 15.7-15.7 41.2-15.7 56.9 0L726 482.8c8.3 8.3 12.3 19.4 11.7 30.3 0.5 10.9-3.4 22-11.7 30.3L367 902.5c-15.7 15.7-41.2 15.7-56.9 0-15.7-15.7-15.7-41.2 0-56.9l332.4-332.5z" p-id="4287" fill="#C4C4C4"></path></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 社会影像 -->
                <div class="socialImpactModule" id="nav4">
                    <div class="socialImpactTop">
                        <el-carousel ref="socialCarousel" trigger="click">
                            <el-carousel-item v-for="item in SocialImages" :key="item.id">
                                <img class="carouselItemImg" :src="item.src" :alt="`img-${item.id}`">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="socialImpactBot">{{ $t('news.newsText') }}</div>
                </div>
                <!-- 就業機會 -->
                <div class="employmentModule" id="nav5">
                    <div class="itemModulesTitle">
                        <span class="line"></span>
                        <span class="titleText">{{ $t('common.navEmployment') }}</span>
                    </div>
                    <div class="employmentCarousel">
                        <div class="employmentCarouselTop">
                            <el-carousel ref="employmentCarousel" trigger="click">
                                <el-carousel-item v-for="item in swiperList" :key="item.id">
                                    <img class="carouselItemImg" :src="item.src" :alt="`img-${item.id}`">
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                        <div class="employmentCarouselBot">{{ $t('employment.employmentText1') }}</div>
                    </div>
                    <ul class="welfareList">
                        <li class="itemWelfare">
                            <img class="itemWelfareIcon" src="../assets/images/about/医院_hospital-two@2x.webp" alt="医院_hospital">
                            <p class="itemWelfareName">{{ $t('employment.welfare1') }}</p>
                        </li>
                        <li class="itemWelfare">
                            <img class="itemWelfareIcon" src="../assets/images/about/喝茶_tea-drink@2x.webp" alt="">
                            <p class="itemWelfareName"> {{ $t('employment.welfare2') }}</p>
                        </li>
                        <li class="itemWelfare">
                            <img class="itemWelfareIcon" src="../assets/images/about/礼物_gift(1)@2x.webp" alt="">
                            <p class="itemWelfareName">{{ $t('employment.welfare3') }}</p>
                        </li>
                        <li class="itemWelfare">
                            <img class="itemWelfareIcon" src="../assets/images/about/生日蛋糕_birthday-cake@2x.webp" alt="">
                            <p class="itemWelfareName">{{ $t('employment.welfare4') }}</p>
                        </li>
                        <li class="itemWelfare">
                            <img class="itemWelfareIcon" src="../assets/images/about/遮阳篷_sunshade@2x.webp" alt="">
                            <p class="itemWelfareName">{{ $t('employment.welfare5') }}</p>
                        </li>
                        <li class="itemWelfare">
                            <img class="itemWelfareIcon" src="../assets/images/about/钞票_paper-money@2x.webp" alt="">
                            <p class="itemWelfareName">{{ $t('employment.welfare6') }}</p>
                        </li>
                        <li class="itemWelfare">
                            <img class="itemWelfareIcon" src="../assets/images/about/理财_financing-one@2x.webp" alt="">
                            <p class="itemWelfareName">{{ $t('employment.welfare7') }}</p>
                        </li>
                        <li class="itemWelfare">
                            <img class="itemWelfareIcon" src="../assets/images/about/市场分析_market-analysis(1)@2x (2).webp" alt="">
                            <p class="itemWelfareName">{{ $t('employment.welfare8') }}</p>
                        </li>
                    </ul>
                </div>
                <!-- 聯絡我們 -->
                <div class="contactUsModule" id="nav6">
                    <div class="itemModulesTitle">
                        <span class="line"></span>
                        <span class="titleText">{{ $t('common.navContactUs') }}</span>
                    </div>
                    <ul class="contactDetails">
                        <li class="itemDetails">
                            <p class="itemDetailsTitle">{{ $t('contactUs.contactText1') }}</p>
                            <p class="itemDetailsText">bd@newborntown.com</p>
                        </li>
                        <li class="itemDetails">
                            <p class="itemDetailsTitle">{{ $t('contactUs.contactText2') }}</p>
                            <p class="itemDetailsText">pr@newborntown.com</p>
                        </li>
                        <li class="itemDetails">
                            <p class="itemDetailsTitle">{{ $t('contactUs.contactText3') }}</p>
                            <p class="itemDetailsText">ir@newborntown.com</p>
                        </li>
                        <li class="itemDetails">
                            <p class="itemDetailsTitle">{{ $t('contactUs.contactTextAdress') }}</p>
                            <p class="itemDetailsText">{{ $t('contactUs.contactTextHKAdress') }}</p>
                            <p class="itemDetailsText">{{ $t('contactUs.contactTextBJAdress') }}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 资讯内容 - 弹窗 -->
            <el-dialog :title="$t('common.text')" :visible.sync="dialog">
                <div class="markdown-body">
                    <VueMarkdown :source="msg" />
                </div>
            </el-dialog>
        </div>
        <div class="mFootWrap">
            <div class="motherMedia">
                <div class="followUs">{{ $t('common.navFollowUs') }}</div>
                <div class="mediaList">
                    <a class="mediaIcon" href="https://x.com/TownNewborn" target="_blank">
                        <img src="../assets/images/index/x@2x.webp" alt="icon">
                    </a>
                    <a class="mediaIcon" href="https://www.linkedin.com/company/newborn-town-technology-international-enterprise/" target="_blank">
                        <img src="../assets/images/index/ins@2x.webp" alt="icon">
                    </a>
                    <a class="mediaIcon" href=" https://www.facebook.com/Newborntown.Inc" target="_blank">
                        <img src="../assets/images/index/facebook@2x.webp" alt="icon">
                    </a>
                    <a class="mediaIcon" href="https://www.youtube.com/@newborntown5952" target="_blank">
                        <img src="../assets/images/index/youtobe@2x.webp" alt="icon">
                    </a>
                </div>
            </div>
            <!-- <img class="mfootLogo" src="../assets/images/index/组合-透明白.webp" alt="M-foot-logo"> -->
        </div>
    </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'
const en = require('@/data/news/en/')
const hk = require('@/data/news/hk/')

export default {
    name: 'MobileIndex',
    components: {
        VueMarkdown
    },
    data() {
        return {
            drawer: false,
            all: [],
            lists: [],
            msg: '',
            dialog: false,
            mobileMapUrl: '',
            SocialImages: [
                { id: 1, src: require(`@/assets/images/news/SocialImages/轮播图1.webp`) },
                { id: 2, src: require(`@/assets/images/news/SocialImages/轮播图2.webp`) },
                { id: 3, src: require(`@/assets/images/news/SocialImages/轮播图3.webp`) },
                { id: 4, src: require(`@/assets/images/news/SocialImages/轮播图4.webp`) },
                { id: 5, src: require(`@/assets/images/news/SocialImages/轮播图5.webp`) }
            ],
            swiperList: [
                { id: 1, src: require(`@/assets/images/about/jean-phili-delberghe-75xPHEQBmvA-unsplash@2x.webp`) },
                { id: 2, src: require(`@/assets/images/about/jean-philippe-delbehe-75xPHEQBmvA-unsplash@2x.webp`) },
                { id: 3, src: require(`@/assets/images/about/jean-philippe-delberghe-75xPHEQBmvA-unsplash@2x.webp`) },
                { id: 4, src: require(`@/assets/images/about/jean-philippe-erghe-75xPHEQBmvA-unsplash@2x.webp`) }
            ],
            headLogo: '',
        };
    },
    watch: {
        '$i18n.locale'(newValue) {
            this.changeLang(newValue)
            if (newValue === 'en') {
                this.mobileMapUrl = require(`@/assets/images/index/地图英文@2x.webp`);
            }
            if (newValue === 'hk') {
                this.mobileMapUrl = require(`@/assets/images/index/加香港海口-05@2x.webp`);
            }
        }
    },
    created() {
        this.changeLang(this.$i18n.locale)
    },
    mounted() {
        // 监听跑马灯1的触摸事件
        const socialCarousel = this.$refs.socialCarousel.$el;
        socialCarousel.addEventListener('touchstart', (e) => this.handleTouchStart(e, 'socialCarousel'));
        socialCarousel.addEventListener('touchmove', this.handleTouchMove);
        socialCarousel.addEventListener('touchend', (e) => this.handleTouchEnd(e, 'socialCarousel'));

        // 监听跑马灯2的触摸事件
        const employmentCarousel = this.$refs.employmentCarousel.$el;
        employmentCarousel.addEventListener('touchstart', (e) => this.handleTouchStart(e, 'employmentCarousel'));
        employmentCarousel.addEventListener('touchmove', this.handleTouchMove);
        employmentCarousel.addEventListener('touchend', (e) => this.handleTouchEnd(e, 'employmentCarousel'));
    },
    methods:{
        changeLanguage(command){
            this.$i18n.locale = command;
        },
        changeLang(lang) {
            this.all = []
            if (lang === 'en') {
                this.all = en
                this.mobileMapUrl = require(`@/assets/images/index/加香港海口-05@2x.webp`);
                this.headLogo = require('@/assets/images/index/headLogo-en@2x.webp');
            }
            if (lang === 'hk') {
                this.all = hk
                this.mobileMapUrl = require(`@/assets/images/index/加香港海口-05@2x.webp`);
                this.headLogo = require('@/assets/images/index/headLogo@2x.webp');
            }
            this.initLists()
        },
        initLists() {
            this.lists = this.all; // 直接渲染整个列表数据
        },
        getnews(file) {
            import('@/markdown/' + file).then(myModule => {
                this.msg = myModule.default;
            });
        },
        show(name) {
            const file = `${this.$i18n.locale}/${name}`;
            this.getnews(file);
            this.dialog = true;
            window.scrollTo(0, 0);
        },
        close() {
            this.dialog = false;
        },

        handleTouchStart(e) {
            // 记录触摸开始的 X 坐标
            this.touchStartX = e.touches[0].clientX;
        },
        handleTouchMove(e) {
            // 更新触摸过程中的 X 坐标
            this.touchEndX = e.touches[0].clientX;
        },
        handleTouchEnd(e, carouselName) {
            const distance = this.touchStartX - this.touchEndX;
            const threshold = 50; // 滑动的阈值，超过 50px 切换图片

            if (Math.abs(distance) > threshold) {
                if (distance > 0) {
                // 向左滑动，切换到下一张图片
                this.$refs[carouselName].next();
                } else {
                // 向右滑动，切换到上一张图片
                this.$refs[carouselName].prev();
                }
            }
        }

    },
    beforeDestroy() {
        // 移除跑马灯1的触摸事件监听器
        const socialCarousel = this.$refs.socialCarousel.$el;
        socialCarousel.removeEventListener('touchstart', this.handleTouchStart);
        socialCarousel.removeEventListener('touchmove', this.handleTouchMove);
        socialCarousel.removeEventListener('touchend', this.handleTouchEnd);

        // 移除跑马灯2的触摸事件监听器
        const employmentCarousel = this.$refs.employmentCarousel.$el;
        employmentCarousel.removeEventListener('touchstart', this.handleTouchStart);
        employmentCarousel.removeEventListener('touchmove', this.handleTouchMove);
        employmentCarousel.removeEventListener('touchend', this.handleTouchEnd);
    }
}
</script>

<style lang="less" scoped>
    body #app .index{
        max-width: 750px;
        margin: 0 auto;
        // background: #FFF !important;
    }
    ::v-deep .el-dialog {
        width: 90% !important;
    }
    ::v-deep .markdown-body p>img {
        width: 100%;
    }
    // 轮播图样式
    ::v-deep .el-carousel-item {
        width: auto;
        height: 160px; 
        display: flex;
        align-items: center;
        justify-content: center;
    }

    ::v-deep .carouselItemImg {
        width: 100%; 
        height: 160px !important;
        object-fit: cover;
    }

    ::v-deep .socialImpactTop {
        width: 100%;
        margin: 0 auto;
    }

    ::v-deep .el-carousel__container {
        height: 160px !important;
        position: relative;
    }

    ::v-deep .carouselItemImg {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    ::v-deep .el-carousel__indicators {
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        // z-index: 1;
    }


    .itemModulesTitle{
        display: flex;
        align-items: center;
        .line{
            display: block;
            width: 3px;
            height: 16px;
            background: #DD520C;
            border-radius: 0px 100px 100px 0px;
        }
        .titleText{
            margin-left: 12px;
            font-weight: 600;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 22px;
            text-align: left;
            font-style: normal;
        }
    }
    .MobileIndex{
        max-width: 750px;
        margin: 0 auto;
        background: #161616 !important;
        box-sizing: border-box;
        .mHeadBox{
            height: 52px;
            position: relative;
            .mHeadWrap{
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
                background: #161616 !important;
                z-index: 10;
                padding: 14px 20px;
                color: #FFF;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .mheadLogo{
                    height: 24px;
                    width: auto;
                }
                .menu{
                    .sideBar{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .icon{
                            width: 21px;
                            height: 20px;
                        }
                    }
                }
            }
        }
        ::v-deep .el-drawer__open .el-drawer.rtl {
            width: 55% !important;
            background: #D9D9D9 !important;
        }
        .sideNavLits {
            display: flex;
            flex-direction: column;
            .itemNav{
                width: 100%;
                padding: 26px 0;
                border-bottom: 1px solid #000000;
                .nav-link{
                    display: block;
                    font-weight: 400;
                    font-size: 15px;
                    color: #000000;
                    line-height: 21px;
                    text-align: center;
                    font-style: normal;
                }
                :hover{
                    .nav-link{
                        color: #DD520C;
                        text-decoration-line: underline;
                    }
                }
            }
        }
        .sidelanguage{
            margin-top: 50px;
            .itemLang{
                width: 100%;
                padding: 14px 0;
                background: #FFFFFF;
                font-weight: 500;
                font-size: 14px;
                color: #000000;
                line-height: 20px;
                text-align: center;
                font-style: normal;
            }
        }
        .mindexCont{
            min-height: 100vh;
            .banner{
                position: relative;
                background: linear-gradient( 180deg, rgba(0,0,0,0) 0%, #111111 100%);
                .bannerImg{
                    height: auto;
                    width: 100%;
                    object-fit: cover;
                }
                .bannerText{
                    width: 100%;
                    padding: 0 5%;
                    text-align: center;
                    box-sizing: border-box; /* 确保padding不会影响元素的整体宽度 */
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    font-weight: 500;
                    font-size: 24px;
                    color: #FFFFFF;
                    line-height: 32px;
                    text-align: text;
                    font-style: normal;
                    white-space: wrap;
                    z-index: 1;
                    overflow: hidden;
                    transform: translate(-50%, -50%);
                }
            }
            .otherModules{
                padding: 0 14px;
                position: relative; 
                .aboutModules {
                    background: #000000;
                    border-radius: 6px;
                    margin-top: -50px;
                    z-index: 2 !important;
                    padding: 20px 0;
                    margin-bottom: 14px;
                    .aboutModulesCont{
                        margin-top: 25px;
                        .aboutbanner{
                            margin: 0 7px;
                            padding-bottom: 14px;
                            border-bottom: 1px solid rgba(104, 104, 104, 0.6);
                            display: flex;
                            justify-content: space-between;
                            .aboutbannerImg{
                                width: 86px;
                                height: 86px;
                                margin-right: 16px;
                            }
                            .aboutbannerText{
                                font-weight: 400;
                                font-size: 12px;
                                color: #888888;
                                line-height: 20px;
                                text-align: left;
                                font-style: normal;
                            }
                        }
                        .aboutApp{
                            margin: 36px 0 44px 0;
                            padding: 0 26px;
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                            .itemAppIcon{
                                width: 38px;
                                height: 38px;
                                margin-right: 10px;
                                margin-bottom: 12px;
                            }
                        }
                        .aboutMap{
                            padding: 0 7px;
                            .mapImg{
                                width: 100%;
                                height: auto;
                            }
                            .digitalInfo{
                                margin-top: 17px;
                                padding: 0 17px;
                                display: flex;
                                align-items: center;
                                justify-content: space-around;
                                .itemInfo {
                                    width: 33.33%;
                                    flex-shrink: 0;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;
                                    .itemInfoNum{
                                        font-weight: 600;
                                        font-size: 18px;
                                        // font-size: 14px;
                                        color: #888888;
                                        line-height: 40px;
                                        font-style: normal;
                                    }
                                    .itemInfoText{
                                        font-weight: 400;
                                        font-size: 12px;
                                        color: #888888;
                                        line-height: 17px;
                                        text-align: center;
                                        font-style: normal;
                                    }
                                }
                            }
                        }
                    }
                }
                .developmentHistoryModule{
                    background: #000000;
                    border-radius: 6px;
                    padding: 20px 0;
                    margin-bottom: 14px;
                    .developmentList{
                        padding: 20px 12px 0 12px;
                        .itemdevelp{
                            display: flex;
                            // align-items: center;
                            border-top: 1px solid;
                            border-image: linear-gradient(270deg, rgba(59, 38, 38, 1), rgba(255, 85, 0, 1)) 1 1;
                            padding-top: 6px;
                            margin-bottom: 20px;
                            .itemEventLeft{
                                height: 100%;
                                display: flex;
                                align-items: center;
                                .point{
                                    width: 4px;
                                    height: 4px;
                                    border-radius: 50%;
                                    background: #FF5500;
                                }
                                .itemTime{
                                    font-weight: 600;
                                    font-size: 16px;
                                    color: #888888;
                                    line-height: 22px;
                                    text-align: left;
                                    font-style: normal;
                                    margin: 0 12px 0 6px;
                                }
                            }
                            .itemEventBox{
                                display: flex;
                                flex-direction: column;
                                .itemEvent{
                                    font-weight: 400;
                                    font-size: 11px;
                                    color: #888888;
                                    line-height: 16px;
                                    text-align: left;
                                    font-style: normal;
                                }
                            }
                        }
                    }
                }
                .newsModules {
                    background: #000000;
                    border-radius: 6px;
                    padding: 20px 0;
                    margin-bottom: 14px;
                    background: linear-gradient( 180deg, rgba(0,0,0,0) 0%, #000000 100%);
                    .newsList {
                        max-height: 375px;
                        overflow: scroll;
                        margin: 25px 12px 0 12px;
                        .itemNewsInfo{
                            padding-bottom: 12px;
                            border-bottom: 1px solid rgba(104, 104, 104, 0.6);
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 18px;
                            .itemNewsImgBox{
                                width: 72px;
                                flex-shrink: 0;
                                .itemNewsImg{
                                    width: 72px;
                                    height: 72px;
                                    object-fit: cover;
                                }
                            }
                            .itemNewsInfoCont{
                                flex: 1;
                                margin-left: 16px;
                                display: flex;
                                flex-direction: column;
                                .itemTopInfoTitle{
                                    font-weight: 400;
                                    font-size: 12px;
                                    color: #888888;
                                    line-height: 20px;
                                    text-align: left;
                                    font-style: normal;
                                }
                                .itemBotInfo{
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    .itemBotInfoTime{
                                        font-weight: 600;
                                        font-size: 10px;
                                        color: #888888;
                                        line-height: 30px;
                                        text-align: left;
                                        font-style: normal;
                                    }
                                    .itemBotInfoMore{
                                        display: flex;
                                        align-items: center;
                                        font-weight: 600;
                                        font-size: 11px;
                                        color: #C4C4C4;
                                        line-height: 30px;
                                        text-align: left;
                                        font-style: normal;
                                        .icon{
                                            width: 12px;
                                            height: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .socialImpactModule{
                    background: #000000;
                    border-radius: 6px;
                    padding: 12px;
                    margin-bottom: 14px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    .socialImpactTop{
                        max-height: 160px;
                        width: 100%;
                    }
                    .socialImpactBot{
                        font-weight: 400;
                        font-size: 12px;
                        color: #888888;
                        line-height: 20px;
                        text-align: left;
                        font-style: normal;
                        margin-top: 12px;
                    }
                }
                .employmentModule{
                    background: #000000;
                    border-radius: 6px;
                    padding: 20px 0;
                    margin-bottom: 25px;
                    .employmentCarousel{
                        display: flex;
                        flex-direction: column;
                        padding: 25px 12px 0px 12px;
                        .employmentCarouselTop{
                            max-height: 160px;
                            width: 100%;
                        }
                        .employmentCarouselBot{
                            font-weight: 400;
                            font-size: 12px;
                            color: #888888;
                            line-height: 20px;
                            text-align: left;
                            font-style: normal;
                            margin-top: 12px;
                        }
                    }
                    .welfareList{
                        margin: 40px 12px 24px 12px;
                        width: auto;
                        display: grid;
                        grid-gap: 20px 0px;
                        grid-template-columns: repeat(4, calc((100% - 0px) / 4));
                        .itemWelfare{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            .itemWelfareIcon {
                                width: 23px;
                                height: 22px;
                                margin-bottom: 14px;
                            }
                            .itemWelfareName{
                                padding: 0 5%;
                                font-weight: 400;
                                font-size: 10px;
                                color: #888888;
                                line-height: 14px;
                                text-align: center;
                                font-style: normal;
                            }
                        }
                    }
                }
                .contactUsModule{
                    .contactDetails{
                        padding: 24px 12px 48px 12px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        .itemDetails{
                            margin-bottom: 22px;
                            .itemDetailsTitle{
                                font-weight: 600;
                                font-size: 15px;
                                color: #FF5500;
                                line-height: 21px;
                                text-align: left;
                                font-style: normal;
                                margin-bottom: 1px;
                            }
                            .itemDetailsText{
                                font-weight: 400;
                                font-size: 13px;
                                color: #888888;
                                line-height: 18px;
                                text-align: left;
                                font-style: normal;
                            }
                        }
                        .itemDetails:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        .mFootWrap{
            padding: 14px 26px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .motherMedia{
                display: flex;
                flex-direction: column;
                .followUs{
                    font-weight: 400;
                    font-size: 10px;
                    color: #888888;
                    line-height: 14px;
                    text-align: left;
                    font-style: normal;
                    margin-bottom: 8px;
                }
                .mediaList{
                    display: flex;
                    align-items: center;
                    .mediaIcon{
                        margin-right: 6px;
                        img{
                            width: 22px;
                            height: 22px;
                        }
                    }
                }
            }
            .mfootLogo{
                height: 10px;
                width: auto;
            }
        }
    }
</style>
