<template>
    <div id="employment">
        <!-- 头部 -->
        <AppHead />
        <div class="aboutWrap">
            <div class="aboutBanner">
                <div class="banner">
                    <el-carousel trigger="click">
                        <el-carousel-item v-for="item in swiperList" :key="item.id">
                                <img class="carouselItemImg" :src="item.src" :alt="`img-${item.id}`">
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="aboutText">{{ $t('employment.employmentText1') }}</div>
            </div>
            <!-- 价值观 -->
            <div class="valuesModule">
                <div class="valuesModuleTitle">
                    <span>{{ $t('employment.our') }}</span>
                    <span>{{ $t('employment.values') }}</span>
                </div>
                <div class="valuesImgBox">
                    <img class="valuesImg" :src="valuesModuleImg" alt="valuesModuleImg">
                </div>
            </div>
            <!-- 福利政策 -->
            <div class="welfareModule">
                <div class="welfareModuleTitle">
                    <span>{{ $t('employment.welfareModuleTitle1') }}</span>
                    <span>{{ $t('employment.welfareModuleTitle2') }}</span>
                </div>
                <ul class="welfareList">
                    <li class="itemWelfare">
                        <img class="itemWelfareIcon" src="../assets/images/about/钞票_paper-money@2x.webp" alt="">
                        <p class="itemWelfareName">{{ $t('employment.welfare6') }}</p>
                    </li>
                    <li class="itemWelfare">
                        <img class="itemWelfareIcon" src="../assets/images/about/理财_financing-one@2x.webp" alt="">
                        <p class="itemWelfareName">{{ $t('employment.welfare7') }}</p>
                    </li>
                    <li class="itemWelfare">
                        <img class="itemWelfareIcon" src="../assets/images/about/市场分析_market-analysis(1)@2x (2).webp" alt="">
                        <p class="itemWelfareName">{{ $t('employment.welfare8') }}</p>
                    </li>
                    <li class="itemWelfare">
                        <img class="itemWelfareIcon" src="../assets/images/about/遮阳篷_sunshade@2x.webp" alt="">
                        <p class="itemWelfareName">{{ $t('employment.welfare5') }}</p>
                    </li>
                    <li class="itemWelfare">
                        <img class="itemWelfareIcon" src="../assets/images/about/医院_hospital-two@2x.webp" alt="医院_hospital">
                        <p class="itemWelfareName">{{ $t('employment.welfare1') }}</p>
                    </li>
                    <li class="itemWelfare">
                        <img class="itemWelfareIcon" src="../assets/images/about/喝茶_tea-drink@2x.webp" alt="">
                        <p class="itemWelfareName"> {{ $t('employment.welfare2') }}</p>
                    </li>
                    <li class="itemWelfare">
                        <img class="itemWelfareIcon" src="../assets/images/about/礼物_gift(1)@2x.webp" alt="">
                        <p class="itemWelfareName">{{ $t('employment.welfare3') }}</p>
                    </li>
                    <li class="itemWelfare">
                        <img class="itemWelfareIcon" src="../assets/images/about/生日蛋糕_birthday-cake@2x.webp" alt="">
                        <p class="itemWelfareName">{{ $t('employment.welfare4') }}</p>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 底部 -->
        <AppFoot />
    </div>
</template>
  
  
<script>
import AppHead from '../components/AppHead.vue'
import AppFoot from '../components/AppFoot.vue'

export default {
    name: 'CareerOpportunities',
    components: {
        AppHead,
        AppFoot
    },
    data() {
        return {
            activeIndex: 'Index',
            // carouselHeight: "6.51rem", 
            valuesModuleImg: '',
            swiperList: [
                { id: 1, src: require(`@/assets/images/about/jean-phili-delberghe-75xPHEQBmvA-unsplash@2x.webp`) },
                { id: 2, src: require(`@/assets/images/about/jean-philippe-delbehe-75xPHEQBmvA-unsplash@2x.webp`) },
                { id: 3, src: require(`@/assets/images/about/jean-philippe-delberghe-75xPHEQBmvA-unsplash@2x.webp`) },
                { id: 4, src: require(`@/assets/images/about/jean-philippe-erghe-75xPHEQBmvA-unsplash@2x.webp`) }
            ]
        }
    },
    watch: {
        '$i18n.locale': {
          immediate: true,  // 立即执行一次以确保在组件加载时正确设置 mapUrl
          handler(newValue) {
            if (newValue === 'en') {
              this.valuesModuleImg = require('@/assets/images/about/企业飞书banner_画板-en.png');
            } else if (newValue === 'hk') {
              this.valuesModuleImg = require('@/assets/images/about/企业飞书banner_画板-hk.png');
            }
          }
        }
    },

}
</script>

<style lang="less" scoped>
    .aboutWrap{
        max-width: 25.6rem;
        margin: 0 auto;
        padding: 1.77rem 2.27rem 2.12rem 2.27rem;
        .aboutBanner{
            margin-bottom: 1.27rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .banner{
                width: 55%;
                height: auto;
                .carouselItemImg {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .el-carousel__indicator--horizontal .el-carousel__button{
                    width: 0.13rem;
                    height: 0.13rem;
                    border-radius: 50%;
                }
            }
            .aboutText{
                flex: 1;
                padding-left: 1rem;
                font-weight: 400;
                font-size: 0.2rem;
                color: #888888;
                line-height: 0.46rem;
                text-align: left;
                font-style: normal;
            }
        }
        .valuesModule{
            margin-bottom: 1.27rem;
            .valuesModuleTitle{
                border-left: 0.15rem solid #FF5500;
                padding: 0 .24rem;
                font-weight: 500;
                font-size: 0.35rem;
                color: #888888;
                line-height: 0.5rem;
                text-align: left;
                font-style: normal;
                display: flex;
                flex-direction: column;
                margin-bottom: 0.85rem;
            }
            .valuesImgBox{
                margin-top: 0.89rem;
                width: 100%;
                height: auto;
                .valuesImg{
                    height: auto;
                    width: 100%;
                }
            }
        }
        .welfareModule {
            .welfareModuleTitle{
                border-left: 0.15rem solid #FF5500;
                padding: 0 .24rem;
                font-weight: 500;
                font-size: 0.35rem;
                color: #888888;
                line-height: 0.5rem;
                text-align: left;
                font-style: normal;
                display: flex;
                flex-direction: column;
                margin-bottom: 0.85rem;
            }
            .welfareList{
                display: grid;
                grid-gap: .15rem .15rem;
                grid-template-columns: repeat(4, calc((100% - .45rem) / 4));
                .itemWelfare{
                    height: 3.5rem;
                    width: auto;
                    background: #222222;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    .itemWelfareIcon {
                        width: .85rem;
                        height: .8rem;
                        margin-bottom: .5rem;
                    }
                    .itemWelfareName{
                        padding: 0 5%;
                        font-weight: 500;
                        font-size: 0.2rem;
                        color: #888888;
                        line-height: 0.32rem;
                        text-align: center;
                        font-style: normal;
                    }
                }
            }
        }
    }
</style>