export default {
  common: {
    navAboutUs: "ABOUT US",
    navDevelop: "MILESTONES",
    navLatestNews: "WHAT'S NEW",
    navNews1: "NEWSROOM",
    navNews2: "SOCIAL IMPACTS",
    navNews3: "MEDIA LIBRARY",
    navInvestor: "INVESTOR RELATIONS",
    navEmployment: "CAREERS",
    navContactUs: "CONTACT",
    navFollowUs: "FOLLOW US",
    hkLanguage: "中文",
    enLanguage: "ENG",
    text: 'Corporate News',
    headLogo: '../assets/images/index/headLogo-en@2x.webp'
  },
  index: {
    bannerText: "To Create Positive Emotional Value Worldwide",
    aboutUsText: "Newborn Town is a tech company that aims to build a content ecosystem where people can connect, have fun, and unleash their creativity. It has churned out dozens of popular apps covering social networking, entertainment, and digital tools.",
    globalOperations: "OPERATION CENTERS",
    globalUsers: "GLOBAL USERS",
    countryAndRegion: "COUNTRIES AND REGIONS COVERED",
    mapUrl: "../assets/images/index/地图英文@2x.webp",
    unit: "1 Billion+"
  },
  development: {
    event1: "Newborn Town was established",
    event2: "Launched Solo Launcher, the first product for global markets",
    event3: "Kicked off MICO, the first social networking product",
    event4: "Launched Solo Aware, the AI-powered engine",
    event5: "Launched the audio-based social app YoHo and the game-oriented social platform TopTop",
    event6: "Listed on HKEX",
    event7: "Launched companion-based social app SUGO and quality casual game series Mergeland",
    event7sub: "which was recommended by Google Play stores worldwide",
    event8: "Started to explore LGBTQ+ social networking business",
    event9: "Rolled out the LGBTQ+ online community app HeeSay",
  },
  contactUs: {
    contactText1: "Business Cooperation",
    contactText2: "Media Enquiries",
    contactText3: "Investor Relations",
    contactTextAdress: "Address",
    contactTextHKAdress: "Hong Kong SAR: Unit 2613, The Center, Queen's Road 99, Central, Hong Kong",
    contactTextBJAdress: "Beijing: Floor 6, Tower B, No.33 Xiaoyun Rd Bldg, Chaoyang District, Beijing",
  },
  employment: {
    employmentText1: "We're committed to fostering a globally diverse workforce and an inclusive workplace culture, enabling each person to get inspired and achieve desired outcomes.",
    welfareModuleTitle1: "BENEFITS",
    welfareModuleTitle2: "PACKAGE",
    our: "OUR",
    values: "VALUES",
    welfare1: "Supplementary Medical Insurance",
    welfare2: "Afternoon Tea",
    welfare3: "Festival and Holiday Benefits",
    welfare4: "Birthday Celebration",
    welfare5: "Paid Annual Leave",
    welfare6: "Competitive Salary",
    welfare7: "Performance Bonuses",
    welfare8: "Stock Options",
  },
  news: {
    newsText: "We actively fulfill our corporate social responsibility globally, striving to deliver positive emotional value to every corner of the world.",
    newsText1: "Download Newborn Town Logo, Brand Guidelines, and Trademark Information",
    newsDownloadBtn: "DOWNLOAD",
    seeMore: "MORE"
  }
}
