<template>
    <div id="investor">
        <!-- 头部 -->
        <AppHead />

        投资者关系
    </div>
</template>
  
  
<script>
import AppHead from '../components/AppHead.vue'

export default {
    name: 'InvestorRelations',
    components: {
        AppHead
    }

}
</script>

<style></style>