<template>
  <div class="AppHeadWrap">
    <div class="AppHeadCont">
        <router-link class="headLogo" to="/">
            <img class="logo" :src="headLogo"  alt="head-logo@2x.webp">
        </router-link>
        <!-- 导航 -->
        <ul class="topNavList">
          <li class="itemNav">
            <a class="nav-link" href="/">{{ $t('common.navAboutUs') }}</a>
            <!-- <router-link class="nav-link" to="/">{{ $t('common.navAboutUs') }}</router-link> -->
          </li>
          <li class="itemNav">
            <router-link class="nav-link" to="/development">{{ $t('common.navDevelop') }}</router-link>
          </li>
          <li class="itemNav newsBox">
            <router-link class="nav-link" to="/news">{{ $t('common.navLatestNews') }}</router-link>
            <ul class="newsList">
              <li class="itemNewsNav"><router-link class="news-link" :to="{ path: '/news', hash: '#newsCenterA' }">{{ $t('common.navNews1') }}</router-link></li>
              <li class="itemNewsNav"><router-link class="news-link" :to="{ path: '/news', hash: '#socialImpactA' }">{{ $t('common.navNews2') }}</router-link></li>
              <li class="itemNewsNav"><router-link class="news-link" :to="{ path: '/news', hash: '#mediaMaterialsA' }">{{ $t('common.navNews3') }}</router-link></li>
            </ul>
          </li>
          <li class="itemNav">
            <a class="nav-link" href="https://ir.newborntown.com/" rel="noopener noreferrer">{{ $t('common.navInvestor') }}</a>
          </li>
          <li class="itemNav">
            <router-link class="nav-link" to="/employment">{{ $t('common.navEmployment') }}</router-link>
          </li>
          <li class="itemNav">
            <router-link class="nav-link" to="/contactUs">{{ $t('common.navContactUs') }}</router-link>
          </li>
        </ul>

        <!-- <div class="text">
          <el-menu :default-active="activeIndex" class="el-menu-demo" background-color="#000000" text-color="#FFFFFF" mode="horizontal" @select="handleSelect">
            <el-menu-item index="DevelopmentHistory">{{ $t('common.navDevelop') }}</el-menu-item>
            <el-submenu index="news">
              <template slot="title">{{ $t('common.navLatestNews') }}</template>
              <el-menu-item index="news1">{{ $t('common.navNews1') }}</el-menu-item>
              <el-menu-item index="news2">{{ $t('common.navNews2') }}</el-menu-item>
              <el-menu-item index="news3">{{ $t('common.navNews3') }}</el-menu-item>
            </el-submenu>
            <el-menu-item index="InvestorRelations">{{ $t('common.navInvestor') }}</el-menu-item>
            <el-menu-item index="CareerOpportunities">{{ $t('common.navEmployment') }}</el-menu-item>
            <el-menu-item index="ContactUs">{{ $t('common.navContactUs') }}</el-menu-item>
          </el-menu>
        </div> -->

        <!-- 多语言 -->
        <div class="languageWrap">
            <div class="languageIcon">
              <svg t="1723706015430" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6939" width="20" height="20"><path d="M512 0C230.4 0 0 230.4 0 512s230.4 512 512 512 512-230.4 512-512S793.6 0 512 0z m0 102.4c56.32 0 112.64 10.24 163.84 35.84v51.2c-5.12-5.12-35.84-30.72-35.84-30.72L399.36 240.64c-15.36 5.12-20.48 30.72-10.24 46.08l97.28 92.16-30.72 46.08-76.8-25.6c-5.12 0-15.36 0-20.48 5.12L240.64 481.28 204.8 563.2 153.6 460.8l-40.96-30.72C148.48 245.76 312.32 102.4 512 102.4z m-409.6 419.84l5.12 5.12 15.36 10.24L184.32 665.6c10.24 20.48 35.84 20.48 46.08 0l66.56-128 87.04-56.32 87.04 30.72c10.24 5.12 20.48 0 30.72-10.24l76.8-117.76c5.12-10.24 5.12-25.6-5.12-30.72L506.88 286.72l117.76-40.96 15.36 15.36c25.6 20.48 56.32 35.84 81.92 25.6 15.36-5.12 25.6-25.6 25.6-46.08V179.2c61.44 46.08 112.64 112.64 143.36 184.32l-35.84 5.12c-5.12 0-15.36 5.12-15.36 10.24-20.48 35.84-71.68 128-66.56 117.76-5.12-5.12-25.6-46.08-35.84-71.68-5.12-15.36-25.6-20.48-35.84-10.24l-133.12 81.92c-10.24 5.12-15.36 25.6-10.24 35.84l102.4 158.72 56.32 30.72c10.24 5.12 15.36 20.48 10.24 30.72l-40.96 81.92v46.08c-51.2 25.6-112.64 40.96-174.08 40.96-225.28 0-404.48-179.2-409.6-399.36z m670.72 307.2l40.96-112.64c5.12-10.24 0-25.6-10.24-30.72l-87.04-51.2-61.44-97.28 35.84-20.48v5.12c20.48 35.84 46.08 56.32 71.68 61.44 35.84 5.12 61.44-20.48 71.68-35.84 30.72-46.08 51.2-76.8 61.44-97.28l20.48-5.12c5.12 20.48 5.12 40.96 5.12 66.56 0 128-56.32 240.64-148.48 317.44z" fill="#FFFFFF" p-id="6940"></path></svg>
            </div>
            <ul class="languageDropdown">
              <li class="item" @click="changeLanguage('hk')">{{ $t('common.hkLanguage') }}</li>
              <li class="item" @click="changeLanguage('en')">{{ $t('common.enLanguage') }}</li>
            </ul>
        </div>
        <!-- <el-dropdown :hide-on-click="false" @command="changeLanguage">
          <div class="el-dropdown-link">
            <svg t="1723706015430" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6939" width="20" height="20"><path d="M512 0C230.4 0 0 230.4 0 512s230.4 512 512 512 512-230.4 512-512S793.6 0 512 0z m0 102.4c56.32 0 112.64 10.24 163.84 35.84v51.2c-5.12-5.12-35.84-30.72-35.84-30.72L399.36 240.64c-15.36 5.12-20.48 30.72-10.24 46.08l97.28 92.16-30.72 46.08-76.8-25.6c-5.12 0-15.36 0-20.48 5.12L240.64 481.28 204.8 563.2 153.6 460.8l-40.96-30.72C148.48 245.76 312.32 102.4 512 102.4z m-409.6 419.84l5.12 5.12 15.36 10.24L184.32 665.6c10.24 20.48 35.84 20.48 46.08 0l66.56-128 87.04-56.32 87.04 30.72c10.24 5.12 20.48 0 30.72-10.24l76.8-117.76c5.12-10.24 5.12-25.6-5.12-30.72L506.88 286.72l117.76-40.96 15.36 15.36c25.6 20.48 56.32 35.84 81.92 25.6 15.36-5.12 25.6-25.6 25.6-46.08V179.2c61.44 46.08 112.64 112.64 143.36 184.32l-35.84 5.12c-5.12 0-15.36 5.12-15.36 10.24-20.48 35.84-71.68 128-66.56 117.76-5.12-5.12-25.6-46.08-35.84-71.68-5.12-15.36-25.6-20.48-35.84-10.24l-133.12 81.92c-10.24 5.12-15.36 25.6-10.24 35.84l102.4 158.72 56.32 30.72c10.24 5.12 15.36 20.48 10.24 30.72l-40.96 81.92v46.08c-51.2 25.6-112.64 40.96-174.08 40.96-225.28 0-404.48-179.2-409.6-399.36z m670.72 307.2l40.96-112.64c5.12-10.24 0-25.6-10.24-30.72l-87.04-51.2-61.44-97.28 35.84-20.48v5.12c20.48 35.84 46.08 56.32 71.68 61.44 35.84 5.12 61.44-20.48 71.68-35.84 30.72-46.08 51.2-76.8 61.44-97.28l20.48-5.12c5.12 20.48 5.12 40.96 5.12 66.56 0 128-56.32 240.64-148.48 317.44z" fill="#FFFFFF" p-id="6940"></path></svg>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="hk">{{ $t('common.hkLanguage') }}</el-dropdown-item>
            <el-dropdown-item command="en">{{ $t('common.enLanguage') }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AppHead',
  // props: {
  //   msg: {
  //     type: String,
  //     required: true
  //   }
  // },
  data() {
    return {
      activeIndex: 'Index',
      headLogo: '',
    }
  },
  computed: {
    ...mapState(['routesName'])
  },
  watch: {
    '$route.name'(newValue) {
      console.log('watch:', newValue)
    },
    '$i18n.locale': {
        immediate: true,  // 立即执行一次以确保在组件加载时正确设置 mapUrl
        handler(newValue) {
          if (newValue === 'en') {
            this.headLogo = require('@/assets/images/index/headLogo-en@2x.webp');
          } else if (newValue === 'hk') {
            this.headLogo = require('@/assets/images/index/headLogo@2x.webp');
          } else {
            this.headLogo = '';
          }
        }
      }
  },
  created() {
    // this.activeIndex = this.routesName[this.$route.name]
  },
  methods: {
    // handleSelect(key, keyPath) {
    //   let menu;
    //   if (keyPath && keyPath.length > 0) {
    //     switch (keyPath[0]) {
    //       case 'AboutUs':
    //         menu = { path: '/aboutUs' };
    //         break;
    //       case 'DevelopmentHistory':
    //         menu = { path: '/development' };
    //         break;
    //       case 'news':
    //       case 'news1':
    //       case 'news2':
    //       case 'news3':
    //         menu = { path: '/news' };
    //         break;
    //       case 'InvestorRelations':
    //         menu = { path: '/investor' };
    //         break;
    //       case 'CareerOpportunities':
    //         menu = { path: '/employment' };
    //         break;
    //       case 'ContactUs':
    //         menu = { path: '/contactUs' };
    //         break;
    //       default:
    //         menu = { path: '/' };
    //         break;
    //     }
    //     this.$router.push(menu);
    //   }
    // },
    changeLanguage(command){
      this.$i18n.locale = command;
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
  .nav-link.router-link-active {
    border-bottom: .013rem solid #FFFFFF;
  }
  .nav-link.router-link-exact-active {
    border-bottom: .013rem solid #FFFFFF;
  }
  .AppHeadWrap{
      height: .8rem;
      .AppHeadCont{
        max-width: 25.6rem;
        background-color: #000000;
        border-bottom: .013rem solid #686868;
        height: .8rem;
        padding: 0 0.853rem;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        z-index: 10;
        .headLogo{
          display: flex;
          height: 100%;
          align-items: center;
          .logo{
            height: 0.35rem;
            width: auto;
          }
        }
        .topNavList{
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .itemNav {
            margin-right: 0.6rem;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .nav-link{
              font-weight: 500;
              font-size: .16rem;
              color: #FFFFFF;
              line-height: .26rem;
              text-align: left;
              font-style: normal;
            }
            &:hover{
              .nav-link{
                border-bottom: .013rem solid #FFFFFF;;
              }
            }
          }
          .newsBox{
            position: relative;
            .newsList{
              display: none;
              width: 1.2rem;
              padding: .32rem;
              background: #FFFFFF;
              position: absolute;
              top: .8rem;
              left: 50%;
              transform: translateX(-50%);
              z-index: 2;
              .itemNewsNav{
                display: flex;
                // align-items: center;
                // justify-content: center;
                margin-bottom: .2rem;
                .news-link{
                  font-weight: 500;
                  font-size: .16rem;
                  color: #000000;
                  line-height: .22rem;
                  font-style: normal;
                }
              }
            }
            &:hover{
              .newsList {
                display: block;
              }
            }
          }
        }
        .languageWrap{
          height: 100%;
          width: 0.66rem;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .languageIcon{
            height: 100%;
            display: flex;
            align-items: center;
            .icon{
              width: .26rem;
              height: .26rem;
            }
          }
          .languageDropdown{
            display: none;
            position: absolute;
            top: .8rem;
            left: 50%;
            transform: translate(-50%);
            padding: .32rem;
            background: #FFFFFF;
            // display: flex;
            align-content: center;
            flex-direction: column;
            z-index: 2;
            .item{
              font-weight: 500;
              font-size: .16rem;
              color: #000000;
              line-height: .22rem;
              text-align: left;
              font-style: normal;
              margin-bottom: .2rem;
              cursor: pointer;
            }
          }
          &:hover{
            .languageDropdown{
              display: flex;
            }
          }
        }
      }

      .text{
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: flex-end;
        align-content: center;
      }
      .el-menu-demo{
        flex: 1;
      }
      .menuList{
        display: flex;
        align-items: center;
        .el-link{
          font-weight: 500;
          font-size: .875rem;
          color: #FFFFFF;
          line-height: 1.25rem;
          text-align: left;
          font-style: normal;
        }
      }
      .el-menu.el-menu--horizontal {
        border-bottom: none;
      }
      .el-menu--horizontal>.el-menu-item.is-active {
          border-bottom: .02rem solid #FFFFFF;
          color: #FFFFFF;
      }
      .el-menu--popup {
        background: #FFFFFF;
      }
      .el-dropdown-link{
        display: flex;
        align-items: center;
        justify-content: center;
        .icon{
          width: 2rem;
          height: 2rem;
        }
      }
      .el-dropdown-menu {
        background: #FFFFFF;
      }
      .el-dropdown-link {
        cursor: pointer;
        color: #000000;
      }
      .el-icon-arrow-down {
        font-size: .012rem;
      }
  }
</style>
