export default {
    common: {
      navAboutUs:"關於我們",
      navDevelop:"發展里程",
      navLatestNews: "最新動態",
      navNews1: "新聞中心",
      navNews2: "社會影響",
      navNews3: "媒體資料",
      navInvestor: "投資者關係",
      navEmployment: "就業機會",
      navContactUs: "聯絡我們",
      navFollowUs: "關注我們",
      hkLanguage: "中文",
      enLanguage: "ENG",
      text: '新聞快訊',
      headLogo: '../assets/images/index/headLogo@2x.webp'
    },
    index: {
      bannerText: "創造美好情緒價值",
      aboutUsText: "赤子城科技是一家全球化的科技公司，圍繞社交、娛樂等領域打造了數十款產品，幫助世界各地的用戶打破空間、年齡、語言和文化等限制，建立更有溫度的連接。",
      globalOperations: "全球運營中心",
      globalUsers: "全球用戶",
      countryAndRegion: "國家和地區覆蓋",
      mapUrl: "../assets/images/index/加香港海口-05@2x.webp",
      unit: "10億+"
    },
    development: {
      event1: "赤子城科技成立",
      event2: "首款全球化產品Solo Launcher上缐",
      event3: "首款社交產品MICO上缐",
      event4: "推出Solo Aware人工智慧引擎",
      event5: "語音社交平台YoHo、游戲社交平台TopTop上線",
      event6: "登陸港交所",
      event7: "精品游戲Mergeland系列上缐，獲谷歌全球推薦",
      event7sub: "陪伴社交平台SUGO上線",
      event8: "開拓「多元人群社交」業務",
      event9: "全新海外多元人群社交品牌HeeSay上線",
    },
    contactUs: {
      contactText1: "商業合作",
      contactText2: "媒體垂詢",
      contactText3: "投資者關係",
      contactTextAdress: "地址",
      contactTextHKAdress: "香港：皇后大道中99號中環中心26層2613室",
      contactTextBJAdress: "北京：北京市朝陽區霄雲路33號大廈B座6層",
    },
    employment: {
      employmentText1: "我們致力於在全球範圍内培養多元化的員工團隊、打造更具包容性的工作環境，讓每個人都能受到啓發並實現預期的成果。",
      welfareModuleTitle1: "我們的",
      welfareModuleTitle2: "福利政策",
      our: "我們的",
      values: "價值觀",
      welfare1: "補充醫療保險",
      welfare2: "下午茶",
      welfare3: "年節福利",
      welfare4: "生日關懷",
      welfare5: "帶薪年假",
      welfare6: "有競爭力的薪酬",
      welfare7: "績效獎金",
      welfare8: "股票期權",

    },
    news: {
      newsText: "我們在全球範圍内積極履行企業社會責任，致力將美好的情緒價值傳遞到每個角落",
      newsText1: "點擊下載Newborn Town徽標、品牌指南和商標信息",
      newsDownloadBtn: "下载",
      seeMore: "查看更多"
    }

  }