// src/router/index.js
import Vue from 'vue';
import Router from 'vue-router';
import HomeIndex from '@/views/index.vue';
import DevelopmentHistory from '@/views/development.vue';
import CareerOpportunities from '@/views/employment.vue';
import LatestNews from '@/views/news.vue';
import InvestorRelations from '@/views/investor.vue';
import ContactUs from '@/views/contactUs.vue';
import MobileIndex from '@/views/mobileIndex.vue';

Vue.use(Router);
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 检测是否为移动设备
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

// 移动端路由
const mobileRoutes = [
  {
    path: '/',
    name: 'MobileIndex',
    component: MobileIndex,
    props: (route) => ({
      lang: route.query.lang
    })
  },
];

// PC 端路由
const pcRoutes = [
  {
    path: '/',
    name: 'HomeIndex',
    component: HomeIndex,
    props: (route) => ({
      lang: route.query.lang
    })
  },{
      path: '/development',
      name: 'DevelopmentHistory',
      component : DevelopmentHistory,
      props: (route) => ({
        lang: route.query.lang
      })
  },{
      path: '/news',
      name: 'LatestNews',
      component : LatestNews,
      props: (route) => ({
        lang: route.query.lang
      })
  },{
      path: '/investor',
      name: 'InvestorRelations',
      component : InvestorRelations,
      props: (route) => ({
        lang: route.query.lang
      })
  },{
      path: '/employment',
      name: 'CareerOpportunities',
      component: CareerOpportunities,
      props: (route) => ({
        lang: route.query.lang
      })
  },{
      path: '/contactUs',
      name: 'ContactUs',
      component: ContactUs,
      props: (route) => ({
        lang: route.query.lang
      })
  }
];

// 根据设备类型选择路由
const routes = isMobile ? mobileRoutes : pcRoutes;


export default new Router({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 如果 savedPosition 存在，返回用户离开时的滚动位置
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      // 如果跳转到带有 hash 的路由，滚动到对应的锚点
      return { selector: to.hash };
    } else {
      // 默认滚动到页面顶部
      return { x: 0, y: 0 };
    }
  },
});

// export default new Router({
//   mode: 'history', // 使用 HTML5 历史模式
//   routes: [
//     {
//       path: '/',
//       name: 'HomeIndex',
//       component: HomeIndex,
//       props: (route) => ({
//         lang: route.query.lang
//       })
//     },{
//         path: '/development',
//         name: 'DevelopmentHistory',
//         component : DevelopmentHistory,
//         props: (route) => ({
//           lang: route.query.lang
//         })
//     },{
//         path: '/news',
//         name: 'LatestNews',
//         component : LatestNews,
//         props: (route) => ({
//           lang: route.query.lang
//         })
//     },{
//         path: '/investor',
//         name: 'InvestorRelations',
//         component : InvestorRelations,
//         props: (route) => ({
//           lang: route.query.lang
//         })
//     },{
//         path: '/employment',
//         name: 'CareerOpportunities',
//         component: CareerOpportunities,
//         props: (route) => ({
//           lang: route.query.lang
//         })
//     },{
//         path: '/contactUs',
//         name: 'ContactUs',
//         component: ContactUs,
//         props: (route) => ({
//           lang: route.query.lang
//         })
//     }
//   ],
// });


