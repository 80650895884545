<template>
    <div id="contactUs">
        <!-- 头部 -->
        <AppHead />
        <div class="contactCont">
            <div class="leftBox">
                <div class="moduleTitle">
                    <span>{{ $t('common.navContactUs') }}</span>
                </div>
                <ul class="contactDetails">
                    <li class="itemDetails">
                        <p class="itemDetailsTitle">{{ $t('contactUs.contactText1') }}</p>
                        <p class="itemDetailsText">bd@newborntown.com</p>
                    </li>
                    <li class="itemDetails">
                        <p class="itemDetailsTitle">{{ $t('contactUs.contactText2') }}</p>
                        <p class="itemDetailsText">pr@newborntown.com</p>
                    </li>
                    <li class="itemDetails">
                        <p class="itemDetailsTitle">{{ $t('contactUs.contactText3') }}</p>
                        <p class="itemDetailsText">ir@newborntown.com</p>
                    </li>
                    <li class="itemDetails">
                        <p class="itemDetailsTitle">{{ $t('contactUs.contactTextAdress') }}</p>
                        <p class="itemDetailsText">{{ $t('contactUs.contactTextHKAdress') }}</p>
                        <p class="itemDetailsText">{{ $t('contactUs.contactTextBJAdress') }}</p>
                    </li>
                </ul>
            </div>
            <div class="rightBox">
                <img class="contactImg" src="../assets/images/contenUs.webp" alt="">
            </div>
        </div>
        <!-- 底部 -->
        <AppFoot />
    </div>
</template>
  
  
<script>
import AppHead from '../components/AppHead.vue'
import AppFoot from '../components/AppFoot.vue'

export default {
    name: 'ContactUs',
    components: {
        AppHead,
        AppFoot
    }

}
</script>

<style lang="less">
    .contactCont{
        max-width: 25.6rem;
        margin: 0 auto;
        padding: 1.27rem 2.27rem 2.17rem 2.27rem;
        display: flex;
        justify-content: space-between;
        .leftBox{
            width: 50%;
            .moduleTitle{
                border-left: 0.15rem solid #FF5500;
                padding: 0 .27rem;
                font-weight: 500;
                font-size: 0.35rem;
                color: #888888;
                line-height: 0.48rem;
                text-align: left;
                font-style: normal;
                display: flex;
                flex-direction: column;
                margin-bottom: 0.85rem;
            }
            .contactDetails{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                .itemDetails{
                    margin-bottom: .64rem;
                    .itemDetailsTitle{
                        font-weight: 600;
                        font-size: 0.29rem;
                        color: #FF5500;
                        line-height: 0.4rem;
                        text-align: left;
                        font-style: normal;
                        margin-bottom: .12rem;
                    }
                    .itemDetailsText{
                        font-weight: 400;
                        font-size: 0.2rem;
                        color: #888888;
                        line-height: 0.43rem;
                        text-align: left;
                        font-style: normal;
                    }
                }
                .itemDetails:last-child{
                    margin-bottom: 0;
                }
            }
        }
        .rightBox{
            width: 50%;
            display: flex;
            align-items: flex-end;
            .contactImg {
                // width: 9.92rem;
                // height: 5.88rem;
                width: 100%;
            }
        }
    }
</style>