import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';

// 完整引入 Element
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en'
import VueI18n from 'vue-i18n'

// 引入自定义的语言包
import hk from './lang/hk'
import en from './lang/en'

// 使用 VueI18n
Vue.use(VueI18n)

// 配置 ElementUI 使用国际化
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})

// 设置多语言配置
const messages = {
  en: {
    ...en        // 自定义的英文语言包
  },
  hk: {
    ...hk        // 自定义的繁体中文语言包
  }
}

// 实例化 i18n
const i18n = new VueI18n({
  locale: 'hk', // 设置默认语言
  messages      // 设置语言包
})


Vue.use(ElementUI, { locale })

Vue.config.productionTip = false;

// 创建 Vue 实例
new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
