<template>
    <div id="news">
        <!-- 头部 -->
        <AppHead />
        <div class="newsWrap">
            <div class="alink" id="newsCenterA"></div>
            <div class="newsList">
                <div class="moduleTitle">
                    <span>{{ $t('common.navNews1') }}</span>
                </div>
                <div class="lists">
                    <div v-for="item, index in lists" :key="index" class="cards" @click.stop="show(item.name)">
                        <div class="img">
                            <img class="size" :src="item.imgUrl" alt="" srcset="">
                        </div>
                        <div class="cardInfo">
                            <div class="text">{{ item.title }}</div>
                            <div class="moreBox">
                                <div class="date">{{ item.date }}</div>
                                <div class="seeMore">{{ $t('news.seeMore') }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="page">
                    <el-pagination
                        layout="pager"
                        :total="total"
                        :page-size="pagesize"
                        :current-page="page"
                        @current-change="changePage($event)"
                    />
                </div>
            </div>
            <div class="alink" id="socialImpactA"></div>
            <div class="socialImpact" >
                <div class="moduleTitle">
                    <span>{{ $t('common.navNews2') }}</span>
                </div>
                <div class="socialImpactCont">
                    <div class="socialImpactLeft">
                        <el-carousel trigger="click">
                            <el-carousel-item v-for="item in SocialImages" :key="item.id">
                                <img class="carouselItemImg" :src="item.src" :alt="`img-${item.id}`">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="socialImpactRight">{{ $t('news.newsText') }}</div>
                </div>
            </div>
            <div class="alink" id="mediaMaterialsA"></div>
            <div class="mediaMaterials" >
                <div class="moduleTitle">
                    <span>{{ $t('common.navNews3') }}</span>
                </div>
                <div class="mediaMaterialsCont">
                    <img class="medialogo" src="../assets/images/index/foot-logo@2x.webp" alt="logo">
                    <p class="mediaText">{{ $t('news.newsText1') }}</p>
                    <a class="mediaDownload" href="../../download/NewbornTown-LOGO.zip">{{ $t('news.newsDownloadBtn') }}</a>
                </div>
            </div>
        </div>
        <el-dialog :title="$t('common.text')" :visible.sync="dialog">
            <div class="markdown-body">
                <VueMarkdown :source="msg" :breaks="true" />
            </div>
        </el-dialog>
        <!-- 底部 -->
        <AppFoot />
    </div>
</template>
  
  
<script>
import AppHead from '../components/AppHead.vue'
import AppFoot from '../components/AppFoot.vue'
import VueMarkdown from 'vue-markdown'
const en = require('@/data/news/en/')
const hk = require('@/data/news/hk/')

export default {
    name: 'LatestNews',
    components: {
        VueMarkdown,
        AppHead,
        AppFoot,

    },
    data() {
    return {
      all: [],
      lists: [],
      msg: '',
      dialog: false,

      total: 10,
      pagesize: 9,
      page: 1,
      SocialImages: [
        { id: 1, src: require(`@/assets/images/news/SocialImages/轮播图1.webp`) },
        { id: 2, src: require(`@/assets/images/news/SocialImages/轮播图2.webp`) },
        { id: 3, src: require(`@/assets/images/news/SocialImages/轮播图3.webp`) },
        { id: 4, src: require(`@/assets/images/news/SocialImages/轮播图4.webp`) },
        { id: 5, src: require(`@/assets/images/news/SocialImages/轮播图5.webp`) }
      ]
    }
  },
  watch: {
    '$i18n.locale'(newValue) {
      this.changeLang(newValue)
    }
  },
  created() {
    this.changeLang(this.$i18n.locale)
  },
  methods: {
    changeLang(lang) {
      this.all = []
      if (lang === 'en') {
        this.all = en
      }
      if (lang === 'hk') {
        this.all = hk
      }
      this.initLists()
    },
    initLists() {
      this.total = this.all.length
      this.getNewLists(1)
    },
    changePage(item) {
      this.getNewLists(item)
    },
    getNewLists(page) {
      this.page = page
      this.lists = this.all.slice((page - 1) * this.pagesize, page * this.pagesize)
      window.scrollTo(0, 0)
    },
    getnews(file) {
      import('@/markdown/' + file).then(myModule => {
        this.msg = myModule.default
      })
    },
    show(name) {
      const file = `${this.$i18n.locale}/${name}`
      this.getnews(file)
      this.dialog = true
      window.scrollTo(0, 0)
    },
    close() {
      this.dialog = false
    }
  }

}
</script>

<style lang="less" scoped>
    ::v-deep .markdown-body p {
        white-space: pre-line;
        margin-top: 0.2rem;
    }
    ::v-deep .markdown-body p>img {
        width: 100%;
    }

    ::v-deep .el-dialog {
        border-radius: .2rem;
    }
    ::v-deep .el-dialog__body {
        word-break: break-word !important;
    }

    ::v-deep .el-dialog {
        border-radius: .2rem;
    }
    ::v-deep .el-dialog__body {
        word-break: break-word !important;
    }

    ::v-deep .el-pagination .el-pager li {
        margin: 0 .06rem;
        border-radius: 50%;
        display: inline-block;
        min-width: .1rem !important;
        height: .115rem !important;
        text-align: center;
        line-height: normal;
        background-color: #f2f2f2;
        color: #666;
        font-size:0;
    }
        
    ::v-deep .el-pager li.active {
        background: #FF5500;
        color: #fff;
        transform: scale(1.5);
    }
        
    ::v-deep .el-pagination.btn-prev,
    .el-pagination.btn-next {
        background-color: #f2f2f2;
        color: #666;
        width: .2rem;
        height: .2rem;
        line-height: .2rem;
    }
        
    ::v-deep .el-pagination.btn-prev:hover,
    .el-pagination.btn-next:hover {
        background-color: #409eff;
        color: #fff;
    }

    ::v-deep .carousel-wrapper {
        width: 100%; /* 父元素宽度 */
        margin: 0 auto; /* 居中对齐 */
    }

    ::v-deep .el-carousel__container {
        width: 100% !important; /* 覆盖默认宽度设置 */
        height: 320px !important; /* 高度自适应 */
    }

    ::v-deep .carousel-item-img {
        width: 100%; /* 图片宽度自适应 */
        height: auto; /* 保持纵横比 */
        object-fit: cover; /* 图片覆盖整个容器 */
    }

    .moduleTitle{
        border-left: 0.15rem solid #FF5500;
        padding: 0 .27rem;
        font-weight: 500;
        font-size: 0.35rem;
        color: #888888;
        line-height: 0.48rem;
        text-align: left;
        font-style: normal;
        display: flex;
        flex-direction: column;
        margin-bottom: 0.85rem;
    }

    .newsWrap{
        max-width: 25.6rem;
        margin: 0 auto;
        padding: .97rem 2.27rem 0 2.27rem;
        .alink{
            height: 0.8rem;
        }
        .newsList{
            margin-top: 0.3rem;
            .lists {
                display: grid;
                grid-gap: .2rem .43rem;
                grid-template-columns: repeat(3, calc((100% - .86rem) / 3));
                .cards{
                    background: #222222;
                    display: flex;
                    flex-direction: column;
                    .img{
                        width: 100%;
                        overflow: hidden;
                        .size{
                            width: 100%;
                            height: 3.09rem;
                            object-fit: cover;
                        }
                    }
                    .cardInfo{
                        flex: 1;
                        margin-top: auto;
                        padding: 0.32rem 0.4rem;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .text{
                            font-weight: 400;
                            font-size: 0.21rem;
                            color: #888888;
                            line-height: 0.35rem;
                            text-align: left;
                            font-style: normal;
                            margin-bottom: .28rem;
                        }
                        .moreBox{
                            padding-top: .24rem;
                            border-top: 0.01rem solid #414141;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .date{
                                font-weight: 600;
                                font-size: 0.16rem;
                                color: #888888;
                                line-height: 0.4rem;
                                text-align: left;
                                font-style: normal;
                            }
                            .seeMore{
                                background: #CACACA;
                                padding: .03rem .23rem;
                                font-weight: 400;
                                font-size: 0.2rem;
                                color: #222222;
                                line-height: 0.4rem;
                                text-align: left;
                                font-style: normal;
                                cursor: pointer;
                                &:hover{
                                    background: #FFFFFF;
                                }
                            }
                        }
                    }
                }
            }
            .page{
                display: flex;
                align-content: center;
                justify-content: center;
                margin-top: .83rem;
            }   
        }
        .socialImpact{
            margin-top: .3rem;
            margin-bottom: .85rem;
            .socialImpactCont{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .socialImpactLeft{
                    // width: 11.76rem;
                    width: 55%;
                    .carouselItemImg {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .socialImpactRight{
                    max-width: 6rem;
                    margin-left: .5rem;
                    font-weight: 400;
                    font-size: 0.2rem;
                    color: #888888;
                    line-height: 0.46rem;
                    text-align: left;
                    font-style: normal;
                }
            }
        }
        .mediaMaterials {
            margin-top: .3rem;
            margin-bottom: 2.12rem;
            .mediaMaterialsCont{
                margin-top: .96rem;
                padding: .97rem 1.07rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: 0.03rem solid #2F2F2F;
                .medialogo{
                    width: 2.13rem;
                    height: auto;
                }
                .mediaText{
                    font-weight: 400;
                    font-size: 0.24rem;
                    color: #888888;
                    line-height: 0.38rem;
                    font-style: normal;
                    margin: 0 .5rem;
                }
                .mediaDownload{
                    padding: .16rem 1.03rem;
                    background: #CACACA;
                    font-weight: 500;
                    font-size: 0.27rem;
                    color: #222222;
                    line-height: 0.4rem;
                    text-align: left;
                    font-style: normal;
                    cursor: pointer;
                    &:hover{
                        background: #FFFFFF;
                    }
                }
            }
        }
    }
    .markdown-body p > img {
        width: 100%;
    }
</style>