<template>
    <div class="AppFootWrap"> 
        <div class="AppFoot">
          <div class="appFootBox">
              <div class="appFootCont">
                  <ul class="footNavList">
                      <li class="footItemNav" :class="{ active: isActive('/') }">
                          <router-link class="footNavText" to="/">{{ $t('common.navAboutUs') }}</router-link>
                      </li>
                      <li class="footItemNav"  :class="{ active: isActive('/development') }">
                          <router-link class="footNavText" to="/development">{{ $t('common.navDevelop') }}</router-link>
                      </li>
                      <li class="footItemNav footNews" :class="{ active: isActive('/news') }">
                          <router-link class="footNavText" to="/news">{{ $t('common.navLatestNews') }}</router-link>
                          <ul class="footNewsList">
                              <li class="itemNew" :class="{ active: isActive('/news') }"><router-link class="itemNewText" :to="{ path: '/news', hash: '#newsCenterA' }">{{ $t('common.navNews1') }}</router-link></li>
                              <li class="itemNew" :class="{ active: isActive('/news') }"><router-link class="itemNewText" :to="{ path: '/news', hash: '#socialImpactA' }">{{ $t('common.navNews2') }}</router-link></li>
                              <li class="itemNew" :class="{ active: isActive('/news') }"><router-link class="itemNewText" :to="{ path: '/news', hash: '#mediaMaterialsA' }">{{ $t('common.navNews3') }}</router-link></li>
                          </ul>
                      </li>
                      <li class="footItemNav">
                          <a class="footNavText" href="https://ir.newborntown.com/" rel="noopener noreferrer">{{ $t('common.navInvestor') }}</a>
                      </li>
                      <li class="footItemNav"  :class="{ active: isActive('/employment') }">
                          <router-link class="footNavText" to="/employment">{{ $t('common.navEmployment') }}</router-link>
                      </li>
                      <li class="footItemNav"  :class="{ active: isActive('/contactUs') }">
                          <router-link class="footNavText" to="/contactUs">{{ $t('common.navContactUs') }}</router-link>
                      </li>
                  </ul>
                  <div class="otherMedia">
                    <div class="followUs">{{ $t('common.navFollowUs') }}</div>
                    <div class="mediaAndLogo">
                      <div class="mediaBox">
                        <a class="mediaIcon" href="https://x.com/TownNewborn" target="_blank">
                          <img src="../assets/images/index/x@2x.webp" alt="icon">
                        </a>
                        <a class="mediaIcon" href="https://www.linkedin.com/company/newborn-town-technology-international-enterprise/" target="_blank">
                          <img src="../assets/images/index/ins@2x.webp" alt="icon">
                        </a>
                        <a class="mediaIcon" href=" https://www.facebook.com/Newborntown.Inc" target="_blank">
                          <img src="../assets/images/index/facebook@2x.webp" alt="icon">
                        </a>
                        <a class="mediaIcon" href="https://www.youtube.com/@newborntown5952" target="_blank">
                          <img src="../assets/images/index/youtobe@2x.webp" alt="icon">
                        </a>
                      </div>
                      <!-- <router-link class="footLogo" to="/index">
                        <img class="logo" src="../assets/images/index/foot-logo@2x.webp" alt="head-logo@2x.webp">
                      </router-link> -->
                    </div>
                  </div>
              </div>
          </div>
        </div>
    </div>
</template>
  
<script>
import { mapState } from 'vuex'

export default {
  name: 'AppFoot',
  data() {
    return {
      activeIndex: 'Index'
    }
  },
  computed: {
    ...mapState(['routesName'])
  },
  watch: {
    '$route.name'(newValue) {
      this.activeIndex = this.routesName[newValue];
      console.log('watch:', newValue);
    }
  },
  created() {
    this.activeIndex = this.routesName[this.$route.name] || 'Index';
  },
  methods: {
      isActive(route) {
          return this.$route.path === route;
      }
  }
}
</script>

<style lang="less">
.AppFootWrap {
  // background-color: #000000;

  .AppFoot {
    max-width: 25.6rem;
    margin: 0 auto;


    .appFootBox {
      border-top: 0.013rem solid rgba(104, 104, 104, 0.5);

      .appFootCont {
        padding: 1rem 1.26rem 1rem 1.26rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;  /* 防止内容溢出 */

        .footNavList {
          display: flex;

          .footItemNav {
            display: flex;
            margin-right: 0.7rem;

            .footNavText {
              font-weight: 400;
              font-size: 0.20rem;
              color: #ffffff;
              line-height: 0.33rem;
              text-align: left;
              font-style: normal;
            }
          }

          .footNews {
            display: flex;
            flex-direction: column;

            .footNewsList {
              .itemNew {
                display: flex;
                margin-top: 0.25rem;

                .itemNewText {
                  font-weight: 300;
                  font-size: 0.186rem;
                  color: #888888;
                  line-height: 0.26rem;
                  text-align: left;
                  font-style: normal;
                }
              }
            }
          }
        }
        
        .otherMedia {
          display: flex;
          
          .followUs {
            font-weight: 500;
            font-size: 0.2rem;
            color: #ffffff;
            line-height: 0.33rem;
            text-align: left;
            font-style: normal;
            margin-right: 0.5rem;
          }
          
          .mediaAndLogo {
            display: flex;
            flex-direction: column;
            .mediaBox{
              display: flex;
              align-items: center;
              .mediaIcon {
                display: flex;
                margin-right: 0.1rem;
                
                img {
                  height: 0.48rem;
                  width: 0.48rem;
                }
              }
            }
            .footLogo {
              display: flex;
              margin-top: 0.45rem;
              .logo {
                width: auto;
                height: 0.35rem;
              }
            }
          }
        }
      }
    }
  }
}

</style>
